// import React from "react";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Alert, Modal, ModalBody } from "reactstrap";
import moment from "moment";
import { getEnv } from "@bkry/bowline-utils";

const weeksBetween = (d1, d2) => {
  return (d2 - d1) / (7 * 24 * 60 * 60 * 1000);
};

const LicenseAlert = (props, context) => {
  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");
  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );
  const dueAtString = projectData?.pending_orders_since;
  if (!dueAtString) return "";
  const dueAt = new Date(dueAtString);
  const now = new Date();
  const weeks = weeksBetween(dueAt, now);
  if (weeks < 2) return "";
  const dueFourWeeks = moment(dueAt).add(4, "weeks").calendar();
  let alertLevel = 1;
  if (weeks > 4) alertLevel = 2;

  return (
    <>
      {alertLevel == 1 && (
        <Alert className="text-center" color="warning">
          <h4 className="alert-heading">
            {context.t(" ⚠ This app will be sunset on {dueFourWeeks} ⚠", {
              dueFourWeeks: dueFourWeeks,
            })}
          </h4>
        </Alert>
      )}
      {alertLevel > 1 && (
        <Modal isOpen size="xl" centered scrollable>
          <ModalBody className="m-3 m-md-5 text-center">
            <h1 className="text-danger">
              {context.t(" ⚠ This app is currently deactivated ⚠ ")}
            </h1>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

LicenseAlert.contextTypes = {
  t: PropTypes.func.isRequired,
};

LicenseAlert.propTypes = {};

LicenseAlert.defaultProps = {};

export default LicenseAlert;
