export const translations = {
  "de": {
    "Powered by": "Powered by",
    "Connect": "Verbinden",
    "Wallet": "Wallet",
    "New alerts": "Neue Benachrichtigungen",
    "Not signed in with Wallet": "Nicht mit Wallet angemeldet",
    "Account": "Konto",
    "Balance": "Kontostand",
    "Signing Request": "Signieranfrage",
    "Cancel": "Abbrechen",
    "Sign": "Unterzeichnen",
    "You are not signed in with your Wallet yet": "Du bist noch nicht mit deinem Wallet angemeldet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Du wirst gebeten, eine Nachricht zu signieren, um eine sichere Login-Verbindung für Dein Wallet herzustellen. Wir haben keinen Zugriff auf Dein Guthaben und NFTs und führen keine Aktionen mit diesen Informationen durch.",
    "You are signed in with your Wallet": "Du bist mit deinem Wallet angemeldet",
    "Copy address": "Adresse kopieren",
    "Tokens": "Tokens",
    "Settings": "Einstellungen",
    "Activity": "Aktivität",
    "Please \"Sign in with Wallet\" first.": "Bitte nutze erst “Anmelden mit Wallet”.",
    "See above": "Siehe oben",
    "Change E-mail address": "Ändere E-Mail-Adresse",
    "Change": "Ändern",
    "Delete Account": "Konto löschen",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Um dein Konto zu löschen, kontaktiere uns bitte unter: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Weitere Informationen zur Verarbeitung Deiner personenbezogenen Daten findest Du in unserer <a href='https://www.bowline.app/data-protection/' target='_blank'>Datenschutzerklärung</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Nutzungsbedingungen</a>.",
    "No Activity yet in this session": "Noch keine Aktivität in dieser Sitzung",
    "Recent Signatures": "Letzte Signaturen",
    "Transactions": "Transaktionen",
    "Disconnect": "Trennen",
    "Your Tokens related to this project": "Deine Tokens für dieses Projekt",
    "Reload Tokens": "Tokens neu laden",
    "You do not have any Token in Wallet for this project": "Du hast keine Token in Deinem Wallet für dieses Projekt",
    "Reload": "Neu laden",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Dein Wallet kann auch andere Tokens von anderen dApps enthalten. Bitte besuche die Websites der Projekte, um sie zu sehen, oder überprüfe Dein Wallet auf zum Beispiel",
    "or": "oder",
    "Connect to Wallet": "Mit Wallet verbinden",
    "close": "schließen",
    "Select a Wallet": "Wallet auswählen",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Mit der Verbindung Deines Walles akzeptierst Du unsere <a href='https://www.bowline.app/tos/' target='_blank'>Nutzungsbedingungen</a>.",
    "How to connect?": "Wie verbinden?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Für eine sichere Verbindung und Interaktion mit diesem Dienst benötigst Du ein Wallet, die das Web3-Protokoll unterstützt. Bitte wählen Sie eine der folgenden Optionen, um eine Verbindung zu Ihrer Geldbörse herzustellen.",
    "I do have a wallet.": "Ich habe ein Wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "Falls Du bereits ein Wallet haben solltest, wähle bitte MetaMask, Ledger, Coinbase oder Wallet Connect, um dich mit Deinem Wallet zu verbinden.",
    "No wallet yet?": "Du hast noch kein Wallet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "Kein Problem, Du hast die Möglichkeit, sehr einfach ein persönliches Wallet zu erstellen, indem Du ein soziales Konto nutzt. Wir erstellen für Dich im Hintergrund ein non-custodial Wallet und Du kannst Deinen privaten Schlüssel bei Bedarf exportieren.",
    "Connect with Coinbase Wallet": "Mit Coinbase Wallet verbinden",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet ist eine weit verbreitete und häufig genutzte Wallet, die den Nutzern den Zugang zum Web 3 Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Want to learn more about this wallet?": "Möchtest du mehr Über dieses Wallet erfahren?",
    "Learn how to install and setup this wallet on": "Erfahre hier, wie Du dieses Wallet Installieren und einrichten kannst",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Wir haben erst kürzlich die Unterstützung für dieses Wallet hinzugefügt. Wenn Du ein Problem hast, lass es uns bitte wissen.",
    "Connect with Ledger": "Mit Ledger verbinden",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger ist eine weit verbreitete und häufig genutzte Geldbörse, die den Nutzern den Zugang zum Web-3-Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Sobald Du auf die Schaltfläche Verbinden klickst, kannst Du Ledger Live und Ledger Connect von Deinem Computer oder Mobiltelefon aus nutzen.",
    "Connect with MetaMask": "Mit MetaMask verbinden",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask ist eine weit verbreitete und häufig genutzte Geldbörse, die den Nutzern den Zugang zum Web 3 Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Please use your existing MetaMask wallet.": "Bitte nutze Dein existierendes MetaMask Wallet.",
    "Connect with your preferred wallet": "Verbinde dich mit Deinem bevorzugten Wallet",
    "With WalletConnect you can connect to almost any existing wallet.": "Mit WalletConnect kannst Du dich mit fast jedem bestehendem Wallet verbinden.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Bitte klicke auf WalletConnect und scanne entweder den QR-Code mit der Wallet deiner Wahl oder wähle aus der vorgegebenen Liste der kompatiblen Wallets.",
    "Connect with your Google Account": "Mit Google Account verbinden",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Wenn Sie noch keine Krypto-Wallet hast, kannst Du deine aktuellen sozialen Konten wie Google oder Facebook nutzen, um eine Brieftasche zu erstellen.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "Wir haben keinen Zugang zu Deinen privaten Schlüsseln, was bedeutet, dass wir nicht in der Lage sind, Deine Güter in Deinem Namen zu verwalten. Du, und nur Du, behältst die vollständige Kontrolle und das Eigentum über Dein Wallet und den dazugehörigen privaten Schlüssel.",
    "Connect with Google": "Mit Google verbinden",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Um eine Verbindung mit Deinem Google-Konto herzustellen, musst Du einer vertrauenswürdigen Anwendung eines Drittanbieters die Genehmigung erteilen, ein Konto für ein soziales Netzwerk zu erstellen, das die sichere Verwaltung Deines non-custodial Wallets gewährleistet.",
    "Connect to your Wallet via Social Connect.": "Verbinde Dein Wallet über Social Connect.",
    "Connect with your Meta Account": "Verbinden mit Meta Account",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect ist ein neuer, einfacher und schneller Weg, eine Wallet zu erstellen. Hierfür nutzt Bowline Deine bestehenden sozialen Konten wie Google oder Facebook, um ein Wallet zu erstellen, die in jeder von Bowline betriebenen dezentralen Anwendung (dApp) verwendet werden kann.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline hat keinen Zugang zu Deinen privaten und finanziellen Informationen, nur Du hast die volle Kontrolle und das Eigentum über Dein Wallet.",
    "Connect with Facebook": "Verbinden mit Facebook",
    "Connect with Metamask": "Verbinden mit Metamask",
    "Connect with Wallet Connect": "Verbinden mit Wallet Connect",
    "Verify your e-mail address": "Bestätige Deine E-Mail-Adresse",
    "The code was sent to your e-mail": "Der Code wurde an deine E-Mail-Adresse geschickt",
    "Please enter the code": "Bitte gib den Code ein",
    "Verify": "Verifizieren",
    "Resend": "Nochmal senden",
    "Discard": "Verwerfen",
    "Attending": "Angemeldet",
    "Type": "Typ",
    "Start": "Start",
    "End": "Ende",
    "Details": "Details",
    "Signed up": "Angemeldet",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Neue Inhalte sind verfügbar. Bitte klicke hier, um die Seite zu aktualisieren und Probleme zu vermeiden.",
    "Home": "Home",
    "Already minted in total": "Insgesamt geminted",
    "Already minted / wallet": "Mit diesem Wallet geminted",
    "Mint for {mintPrice} ETH / each": "Minten für {mintPrice} ETH / pro Stück",
    "Paid Transaction": "Bezahlte Transaktion",
    "Mint": "Mint",
    "Buy Mint": "Mint kaufen",
    "Select an option to pay for this mint": "Wähle eine Option um für diesen Mint zu bezahlen",
    "Pay with Crypto": "Mit Crypto bezahlen",
    "We realized that your balance is too low. You need to have at least ": "Wir haben festgestellt, dass Dein Guthaben zu niedrig ist. Du musst mindestens haben: ",
    "Pay with Credit Card": "Mit Kreditkarte bezahlen",
    "Please wait for the payment to be completed": "Bitte warte bis die Bezahlung abgeschlossen ist",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "Die Transaktion wurde abgeschickt und Du erhältst eine Benachrichtigung in Deinem Bowline Wallet",
    "No tokens available for minting": "Keine Token zum Minten verfügbar",
    "Max tokens minted": "Max. verfügbare Tokens",
    "Mint will start soon. Stay tuned!": "Der Mint startet bald. Schau später vorbei!",
    "Price/NFT": "Preis/NFT",
    "Amount you already minted": "Bereits gemintete Anzahl",
    "Current Price": "Aktueller Preis",
    "e.g. Voucher Code": "z.B. Gutschein Code",
    "Sold out!": "Ausverkauft!",
    "Please visit the": "Bitte besuche den",
    "secondary market.": "Sekundärmarkt.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Du bist derzeit mit einem anderen Netzwerk verbunden als das, in dem dieser Vertrag veröffentlicht ist. Bitte ändere das Netzwerk zu {networkName} in Deinem Wallet.",
    "Switch to Ethereum network": "Wechsle zum Ethereum Netzwerk",
    "Switch to Rinkeby network": "Wechsle zum Rinkeby Netzwerk",
    "Switch to Görli network": "Wechsle zum Görli Netzwerk",
    "Switch to Polygon network": "Wechsle zum Polygon Netzwerk",
    "Switch to Mumbai network": "Wechsle zum Mumbai Netzwerk",
    "Preview": "Vorschau",
    "Edit": "Bearbeiten",
    "Update": "Aktualsieren",
    "Create": "Erstellen",
    "Update Page": "Aktualisiere Seite",
    "Create Page": "Erstelle Seite",
    "Do you want to update this page?": "Willst Du diese Seite aktualisieren?",
    "Do you want to create this page?": "Willst Du diese Seite erstellen?",
    "Set Title": "Setze Titel",
    "Set URL": "Setze URL",
    "Locale": "Sprache",
    "Page Deleted": "Seite gelöscht",
    "Page was deleted successfully!": "Die Seite wurde erfolgreich gelöscht!",
    "Continue": "Weiter",
    "Back": "Zurück",
    "Missing title": "Titel fehlt",
    "Expired": "Abgelaufen",
    "Unlock": "Entsperren",
    "Please connect your wallet to be able to unlock": "Bitte verbinde Dein Wallet damit du entsperren kannst",
    "Welcome to {project}": "Wilkommen bei {project}",
    "{project} asks for your email address": "{project} fragt nach Deiner E-Mail-Adresse",
    "{project} requires your email address to continue": "{project} benötigt Deine E-Mail-Adresse",
    "share email anonymised": "teile E-Mail anonymisiert",
    "(recommended)": "(empfohlen)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} wird nur eine anonymisierte E-Mail-Adresse erhalten, aber bowline wird Deine echte E-Mail-Adresse aufbewahren.",
    "share email": "teile E-Mail",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} erhält Ihre E-Mail-Adresse und verwendet sie gemäß der Datenschutzrichtlinie, und bowline speichert Deine E-Mail-Adresse auch, um Benachrichtigungen zu versenden.",
    "don't share email": "teile E-Mail nicht",
    "Email address": "E-Mail-Adresse",
    "Your stored email": "Deine gespeichert E-Mail",
    "Submit": "Abschicken",
    "By submitting you accept the": "Mit dem Absenden akzeptierst Du die",
    "terms of service": "Servicebedingungen",
    "terms of service for {projectTitle}": "Servicebedingungen von {projectTitle}",
    "Close": "Schliessen",
    "data protection policy": "Datenschutzerkärung",
    "data protection policy for {projectTitle}": "Datenschutzerkärung von {projectTitle}",
    "of {projectTitle}.": "von {projectTitle}",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Weitere Informationen über die Verarbeitung Deiner personenbezogenen Daten durch bowline findest Du in dieser <a href='https://www.bowline.app/data-protection/' target='_blank'>Datenschutzerklärung</a>.",
    "Share": "Teilen",
    "Bid for this Product": "Für dieses Produkt bieten",
    "you must bid at least": "Du musst mindestens bieten",
    "Enter bid": "Gebot eingeben",
    "Bid can't be higher than current balance": "Gebot kann nicht höher sein als das aktuelle Guthaben",
    "Warning: Your bid is lower than the current highest bid": "Warnung: Dein Gebot ist niedriger als das aktuell höchste Gebot",
    "Your balance": "Dein Guthaben",
    "Place your Bid": "Gebot abgeben",
    "You cannot place a bid as you have insufficient funds.": "Du kannst kein Gebot abgeben das dein Guthaben nicht ausreicht.",
    "Please follow the confirmation steps in your wallet.": "Bitte folge den Bestätigungsschritten in deinem Wallet.",
    "Your bid is stored in our database.": "Dein Gebot ist in unserer Datenbank gespeichert.",
    "You have successfully placed your bid!": "Du hast erfolgreich ein Gebot abgegeben!",
    "We will notify you if you are outbid, or if you are the bid winner!": "Wir werden dich benachrichtigen falls du überboten wirst oder falls dein Gebot erfolgreich ist!",
    " view more": " zeige mehr",
    "This is a cached value, the price should soon be loaded from blockchain.": "Dies ist ein zwischengespeicherter Wert, der Preis sollte bald von der Blockchain geladen werden.",
    "Purchase": "Kaufen",
    "Your item purchase was successful": "Dein Kauf war erfolgreich",
    "Okay": "Okay",
    "Order Summary": "Zusammenfassung der Bestellung",
    "Amount: ": "Anzahl: ",
    "Total": "Gesamt",
    "Close Modal": "Schliesse Dialog",
    "Transaction in Progress": "Transaktion in Bearbeitung",
    "Please sign allowance": "Bitte signiere die Erlaubnis",
    "please approve the interaction": "Bitte genehmige die Ineraktion",
    "Checking your balance": "Überprüfung Deines Guthabens",
    "checking if your wallet has enough funds to buy this product": "Wir überprüfen ob dein Wallet genügend Guthaben hat um dieses Produkt kaufen zu können",
    "Please sign transaction": "Bitte signiere die Transaktion",
    "Metamask should open and ask you to sign the transaction": "Metamask sollte sich öffnen und auffordern, die Transaktion zu unterzeichnen",
    "Wait for transaction to be mined": "Warte bis die Transaktion gemined ist",
    "Depending on network traffic this could take a few minutes": "Je nach Netzauslastung kann dies einige Minuten dauern",
    "Something went wrong": "Etwas ist schief gelaufen",
    "We could not purchase the product!": "Wir konnten das Produkt nicht kaufen!",
    "Biling details": "Rechnungsdaten",
    "First name": "Vorname",
    "This field is required": "Dieses Feld ist verpflichtend",
    "Maximum length is 150": "Maximale Länge ist 150",
    "Last name": "Nachname",
    "Company name": "Firmenname",
    "Address": "Adresse",
    "Minimum length is 5": "Minimale Länge ist 5",
    "Maximum length is 250": "Maximale Länge ist 250",
    "Postal Code": "Postleitzahl",
    "City": "Stadt",
    "Country": "Land",
    "Summary": "Zusammenfassung",
    "Products": "Produkte",
    "Price": "Preis",
    "Continue Purchase": "Einkauf fortsetzen",
    "Please Sign in with Wallet first before purchasing a product!": "Bitte melde Dich zuerst mit deinem Wallet an, bevor Du ein Produkt kaufst!",
    "Please connect your wallet first before being able to purchase products": "Bitter verbinde zuerst Dein Wallet bevor Du ein Produkt kaufen kannst",
    "Click Purchase to complete your checkout.": "Klicke auf Kaufen, um Deine Bestellung abzuschließen.",
    "Sign in with Wallet": "Anmelden mit Wallet",
    "Stake All selected": "Stake alle Ausgewählten",
    "Unstake All selected": "Unstake alle Ausgewählten",
    "staked": "staked",
    "Unstake": "Unstake",
    "Stake": "Stake",
    "unclaimed earnings": "nicht beanspruchte Erträge",
    "Your retrieved earnings, that are ready to claim!": "Du hast Erträge, die abgerufen werden können!",
    "pending rewards": "ausstehende Belohnungen",
    "Tokens in the Hole": "Tokens im Loch",
    "Your total Tokens staked": "Anzahl deiner gestakten Tokens",
    "Available Balance": "Verfügbares Guthaben",
    "Your lifetime earnings from staking.": "All Deine jemals erzielten Erträge durch Staking.",
    "Claim": "Abrufen",
    "Authorization required": "Autorisierung erforderlich",
    "The provided voucher code is not valid": "Der angegebene Gutscheincode ist nicht gültig",
    "The provided voucher code got already used. Each voucher code can be used only once.": "Der angegebene Gutscheincode wurde bereits verwendet. Jeder Gutscheincode kann nur einmal verwendet werden.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Ihre Transaktion wurde bereits an die Blockchain gesendet. Es sieht so aus, als hättest Du versucht, denselben Token erneut zu minten. Bitte hab Geduld.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Wenn Du versucht hast, mehr als einen Token zu minten, kann es sein, dass Du von unserem Bot-Schutz erwischt wurdest. Wir werden Deine Transaktion manuell überprüfen und Dir den Token später per Airdrop zukommen lassen.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "Wir konnten Deinen Token derzeit nicht per Airdrop schickem, da derzeit zu viel Verkehr auf der Blockchain herrscht. Dein Airdrop wurde jedoch erfolgreich zur Warteschlange hinzugefügt und Du erhältst Deinen Token, sobald die Transaktion in die Blockchain geschrieben wurde. Dies kann je nach Warteschlangenlänge und Gas bis zu ein paar Stunden dauern.",
    "Attention:": "Achtung:",
    "We can't guarantee the exact order of the transactions.": "Wir können die genaue Reihenfolge der Transaktionen nicht garantieren.",
    "An Error occured!": "Es ist ein Fehler aufgetreten!",
    "show full error message": "Zeige die komplette Error Message an",
    "Processing": "Verarbeitung",
    "Success!": "Erfolgreich!",
    "a content category": "eine Inhaltskategorie",
    "an unlockable content": "einen Entsperrbaren Inhalt",
    "a whitelist entry": "einen Whitelisteintrag",
    "a event": "ein Event",
    "a token": "einen Token",
    "created": "erstellt",
    "updated": "aktualisiert",
    "minted": "geminted",
    "transfered": "übertragen",
    "You successfully {action} {resourceType}": "Du hast erfolgreich {resourceType} {action}",
    "ok": "ok",
    "click to get back to overview screen": "anklicken, um zur Übersicht zurückzukehren",
    "Missing Name": "Fehlender Name",
    "Select": "Auswählen",
    "Selected": "Ausgewählt",
    "Transfer Token": "Token übertragen",
    "Receiver": "Empfäger",
    "Not a valid address!": "Keine gültige Adresse!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Du wirst einen Token an {address} übertragen. Dieser Vorgang kann nicht rückgängig gemacht werden",
    "Transfer this NFT": "Übertrage diesen NFT",
    "Purchase in Progress": "Kauf in Bearbeitung",
    "Signing with your wallet": "Walletsignatur",
    "Please follow your wallet instructions to confirm the transaction.": "Bitte folge den Anweisungen Deines Wallets, um die Transaktion zu bestätigen.",
    "You confirmed the transaction with your wallet.": "Du hast die Transaktion mit Deinem Wallet bestätigt.",
    "Transaction is sent to the blockchain": "Transaktion wird an die Blockchain gesendet",
    "After signing the transaction it is send to the blockchain.": "Nach dem Signieren der Transaktion wird sie an die Blockchain gesendet.",
    "Transaction is being processed": "Transaktion wird gerade bearbeitet",
    "Please check your wallets transaction history for your actual transaction status!": "Bitte überprüfe Deinen Transaktionsverlauf in Deinem Wallet auf den aktuellen Transaktionsstatus!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Du kannst diesen Dialog jederzeit schließen - es hat keinen Einfluss auf Deine Transaktion. Wir werden den Dialog für Dich schließen, sobald wir sehen, dass die Transaktion gemined ist.",
    "Select Tokens for Voting": "Token für die Stimmabgabe auswählen",
    "Select Token for Unlocking": "Token für die Freischaltung auswählen",
    "Select {n} token from these": "Wähle {n} Token aus diesen",
    "And": "Und",
    "Or": "Oder",
    "Sorry you dont have any matching Token for unlocking": "Leider hast Du keinen passenden Token für die Freischaltung",
    "Please fill out those inputs to unlock": "Bitte fülle diese Felder aus um zu entsperren",
    "Entered value does not match email format": "Eingegebener Wert stimmt nicht mit E-Mail-Format überein",
    "Sign Up": "Anmelden",
    "Vote": "Abstimmen",
    "Email": "E-Mail",
    "Change password": "Passwort ändern",
    "Insert your current password and the new password": "Gib Dein aktuelles und neues Passwort ein",
    "Current Password": "Aktuelles Passwort",
    "Password": "Passwort",
    "Save": "Speichern",
    "tokens": "Tokens",
    "community event": "Community Event",
    "partner event": "Partner Event",
    "conference": "Konferenz",
    "irl meetup": "IRL Meetup",
    "irl party": "IRL Party",
    "virtual meetup": "Virtuelles Meetup",
    "discord event": "Discord Event",
    "ask me anything": "Ask me Anything",
    "There are no votings options available": "Es sind keine Abstimmungsoptionen verfügbar",
    "vote": "Abstimmen",
    "Please connect your wallet to be able to vote": "Bitte verbinde Dein Wallet um Abstimmen zu können",
    "Your Vote has been sent successfully": "Deine Stimme wurde erfolgreich gesendet",
    "Reveal": "Veröffentlichung",
    "get more Info": "mehr Infos erhalten",
    "This vote has ended. Thanks!": "Diese Abstimmung ist beendet. Danke!",
    "Wallet Changed": "Wallet geändert",
    "It looks like you changed your wallet, would you like to connect your wallet?": "Es sieht so aus, als hättest du dein Wallet geändert. Möchtest Du Dein Wallet verbinden?",
    "Export Wallet": "Wallet Exportieren",
    "(A QR code will be generated for {n} seconds)": "(Ein QR-Code wird für {n} Sekunden generiert)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Bitte scanne den QR-Code mit der Kamera Deines Handys oder einer Wallet-App (z. B. Metamask), um Dein Wallet zu exportieren, oder klicke auf die Schaltfläche unten, um den Schlüssel zu kopieren.",
    "Copy Private Key": "Privaten Schlüssel kopieren",
    "please complete your profile": "Bitte vervollständige Dein Profil",
    "* means required": "* bedeutet erforderlich",
    "Please connect to your wallet first.": "Bitte verbinde zuerst Dein Wallet.",
    "Contract Address": "Contract Adresse",
    "Add my Wallet to this Allowlist": "Mein Wallet zu dieser Allowlist hinzufügen",
    "Congratulations!": "Glückwunsch!",
    "You've been added to the Allowlist!": "Du wurdest zur Allowlist hinzugefügt!",
    "Do you want to receive notifications on your email about this listing?": "Möchtest du per E-Mail über diese Liste benachrichtigt werden?",
    "Wallet Address": "Wallet Adresse",
    "You are already part of this allowlist!": "Du bist bereits auf dieser Allowlist!",
    "To mint this NFT please connect your wallet": "Um diesen NFT minten zu können muss Dein Wallet verbunden sein",
    "Password Unlock": "Mit Passwort entsperren",
    "This page is password protected.": "Diese Seite ist passwortgeschützt.",
    "Check Password": "Passwort prüfen",
    "No Access": "Zugang verweigert",
    "List of enabled Modules": "Liste der aktivierten Module",
    "show": "zeige",
    "Events": "Events",
    "Unlockables": "Unlockables",
    "Votings": "Votings",
    "Allowlist": "Allowlist",
    "Partner Deals": "Partner Deals",
    "Token Search": "Token Suche",
    "My Tokens": "Meine Token",
    "go to": "gehe zu",
    "Minting": "Minting",
    "Event Type": "Eventart",
    "Start Date": "Startdatum",
    "End Date": "Enddatum",
    "Sign up for this event": "Melde dich für dieses Event an",
    "This event has ended.": "Dieses Event ist beendet.",
    "There was a problem showing this event. Please contact support on discord": "Es gab ein Problem bei der Anzeige dieses Events. Bitte kontaktiere den Support auf Discord",
    "There was a problem signing up for this event. Please contact support on discord": "Bei der Anmeldung zu diesem Event ist ein Problem aufgetreten. Bitte kontaktiere den Support auf Discord",
    "Successfully Unlocked!": "Erfolgreich freigeschalten!",
    "The event has been unlocked.": "Dieses Event wurde freigeschaltet",
    "Upcoming": "Bevorstehend",
    "Completed": "Abgeschlossen",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "Es gab ein Problem bei der Auflistung aller Inhalte. Bitte kontaktiere den Support auf Discord",
    "Page not found": "Seite nicht gefunden",
    "You are not authorized to see this page!": "Du bist nicht berechtigt, diese Seite zu sehen!",
    "Please connect your wallet first": "Bitte verbinde erst deine Wallet",
    "Please sign in with Wallet first": "Bitte melde Dich zuerst mit Deinem Wallet an",
    "You need to own one of our NFTs to view this page.": "Du musst einen unserer NFTs besitzen, um diese Seite anzuzeigen.",
    "Connect your wallet to proceed.": "Verbinde Dein Wallet um fortzufahren",
    "You didn't purchase our NFT yet? Click here to proceed.": "Du hast noch keinen NFT erworben? Klicke hier, um fortzufahren.",
    "Please click Sign in with Ethereum to verify your NFT Ownership.": "Bitte nutze “Sign in with Ethereum” um deine NFT Eigentümerschaft zu überprüfen.",
    "If you have already minted please wait a couple minutes in order for the transaction to be completed and ": "Wenn Du bereits geminted hast, warte bitte ein paar Minuten, damit die Transaktion abgeschlossen werden kann und ",
    "click here ": "hier klicken ",
    "to check your holder status.": "um deinen Halterstatus zu überprüfen.",
    "Become a Holder": "Werde ein Holder",
    "Minting Section": "Minting Bereich",
    "Please connect your Wallet": "Bitte verbinde deine Wallet",
    "Sign in with your crypto wallet or Google account to mint NFTs.": "Melde Dich mit Deinem Krypto-Wallet oder Deinem Google-Konto an, um NFTs zu minten.",
    "Terms and Conditions": "Geschäftsbedingungen",
    "Successfully Minted": "Erfolgreich geminted",
    "The mint was successful.": "Der Mint war erfolgreich",
    "You successfully minted the {tokenTitle} Token.": "Du hast den Token {tokenTitle} erfolgreich geminted",
    "You can see it in your Wallet": "Du kannst es in deinem Wallet sehen",
    "Check your transaction on Polygonscan": "Check Deine Transaktion auf Polygonscan",
    "Check your transaction on Etherscan": "Check Deine Transaktion auf Etherscan",
    "Validating Voucher Code": "Gutscheincode validieren",
    "We are validating your voucher code.": "Wir validieren deinen Gutscheincode.",
    "Generating Airdrop": "Airdrop generieren",
    "The token is going to be airdropped to your wallet.": "Der Token wird in deine Wallet geairdropped",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "Die Transaktion wurde an die Blockchain gesendet und wir warten auf das Ergebnis. Bitte habe Geduld, dies kann einige Zeit dauern",
    "Something went wrong minting your token": "Beim Minten Deines Tokens ist etwas schief gelaufen",
    "We could not mint your Token!": "Wir konnten Deinen Token nicht minten!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Leider ist beim Absenden deiner Transaktion ein Fehler aufgetreten. Bitte versuche es erneut. Wenn der Fehler weiterhin besteht, kontaktiere uns bitte unter support@bowline.app und gebe die vollständige Fehlermeldung an.",
    "Something went wrong validating": "Bei der Validierung ist etwas schief gelaufen",
    "We could not validate your code!": "Wir konnten Deinen Code nicht validieren!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Leider konnten wir deinen Gutscheincode nicht validieren. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere uns bitte unter support@bowline.app und gebe die vollständige Fehlermeldung an.",
    "There was a problem listing all the partner deals. Please contact support on discord": "Es gab ein Problem bei der Auflistung aller Partner-Deals. Bitte kontaktieren Sie den Support auf Discord",
    "Your partner deal has been unlocked": "Dieser Partner-Deal wurde freigeschaltet",
    "There was a problem unlocking this Partner Deal. Please contact support on discord": "Es gab ein Problem bei der Freischaltung dieses Partner-Deals. Bitte kontaktiere den Support auf Discord",
    "Static Page": "Statische Seite",
    "Loading...": "Wird geladen…",
    "Transaction Successful": "Transfer erfolgreich",
    "Your tokens have been successfully minted": "Deine Tokens wurden erfolgreich geminted",
    "Missing name": "Fehlender Name",
    "Check out this Token": "Schau dir diesen Token an",
    "Description": "Beschreibung",
    "Owner": "Besitzer",
    "(You own this token)": "(Du besitzt diesen Token)",
    "Transfer to another Wallet": "Übertragung auf ein anderes Wallet",
    "Traits": "Eigenschaften",
    "Blockchain": "Blockchain",
    "Token ID": "Token ID",
    "Token Standard": "Token Standard",
    "Check on Marketplaces": "Schaue auf Marktplätzen",
    "Rarible": "Rarible",
    "Related": "Ähnlich",
    "Unlockable Contents": "Entsperrbare Inhalte",
    "Token Transferred": "Token übertragen",
    "Your token has been successfully transferred": "Dein Token wurde erfolgreich übertragen",
    "Filter": "Filter",
    "Filter Tokens": "Filter Tokens",
    "Show {n} Tokens": "Zeige {n} Tokens",
    "Secret Description": "Geheime Beschreibung",
    "Secret Files": "Geheime Dateien",
    "There was a problem showing this unlockable content. Please contact support on discord": "Es gab ein Problem beim Anzeigen dieses Inhalts. Bitte kontaktiere den Support auf Discord",
    "There was a problem unlocking this unlockable content. Please contact support on discord": "Es gab ein Problem beim Entsperren dieses Inhalts. Bitte kontaktiere den Support auf Discord",
    "The content has been unlocked successfully": "Der Inhalt wurde erfolgreich freigeschaltet",
    "Filter by Category": "Nach Kategorie filtern",
    "All": "Alle",
    "There are no unlockable contents yet": "Es gibt noch keine Entsperrbaren Inhalte",
    "You will receive a mail with a link to the password reset page.": "Du erhältst eine E-Mail mit einem Link zur Seite zum Zurücksetzen des Passworts.",
    " ⚠ This app will be sunset on {dueFourWeeks} ⚠": " ⚠ Diese App wird eingestellt zum {dueFourWeeks} ⚠",
    " ⚠ This app is currently deactivated ⚠ ": " ⚠ Diese App ist im Moment deaktiviert ⚠ ",
    "immediate": "sofort",
    "after vote": "Nach Stimmabgabe",
    "after end": "Nach dem Ende",
    "Reveal Type": "Veröffentlichung",
    "There was a problem showing this voting. Please contact support on discord": "Es gab ein Problem bei der Anzeige dieser Abstimmung. Bitte kontaktiere den Support auf Discord",
    "There was a problem unlocking this voting. Please contact support on discord": "Es gab ein Problem bei der Freischaltung dieser Abstimmung. Bitte kontaktiere den Support auf Discord",
    "Your Vote has been unlocked successfully": "Deine Stimme wurde erfolgreich gesendet",
    "There are no votings yet": "Es sind noch keine Votings vorhanden",
    "Open": "Öffnen"
  },
  "options": {
    "plural_rule": "n > 1",
    "plural_number": "2"
  },
  "en": {
    "Powered by": "Powered by",
    "Connect": "Connect",
    "Wallet": "Wallet",
    "New alerts": "New alerts",
    "Not signed in with Wallet": "Not signed in with Wallet",
    "Account": "Account",
    "Balance": "Balance",
    "Signing Request": "Signing Request",
    "Cancel": "Cancel",
    "Sign": "Sign",
    "You are not signed in with your Wallet yet": "You are not signed in with your Wallet yet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.",
    "You are signed in with your Wallet": "You are signed in with your Wallet",
    "Copy address": "Copy address",
    "Tokens": "Tokens",
    "Settings": "Settings",
    "Activity": "Activity",
    "Please \"Sign in with Wallet\" first.": "Please \"Sign in with Wallet\" first.",
    "See above": "See above",
    "Change E-mail address": "Change E-mail address",
    "Change": "Change",
    "Delete Account": "Delete Account",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.",
    "No Activity yet in this session": "No Activity yet in this session",
    "Recent Signatures": "Recent Signatures",
    "Transactions": "Transactions",
    "Disconnect": "Disconnect",
    "Your Tokens related to this project": "Your Tokens related to this project",
    "Reload Tokens": "Reload Tokens",
    "You do not have any Token in Wallet for this project": "You do not have any Token in Wallet for this project",
    "Reload": "Reload",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example",
    "or": "or",
    "Connect to Wallet": "Connect to Wallet",
    "close": "close",
    "Select a Wallet": "Select a Wallet",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.",
    "How to connect?": "How to connect?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.",
    "I do have a wallet.": "I do have a wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.",
    "No wallet yet?": "No wallet yet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.",
    "Connect with Coinbase Wallet": "Connect with Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Want to learn more about this wallet?": "Want to learn more about this wallet?",
    "Learn how to install and setup this wallet on": "Learn how to install and setup this wallet on",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "We've just recently added support for this wallet. If you experience any issue please let us know.",
    "Connect with Ledger": "Connect with Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.",
    "Connect with MetaMask": "Connect with MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Please use your existing MetaMask wallet.": "Please use your existing MetaMask wallet.",
    "Connect with your preferred wallet": "Connect with your preferred wallet",
    "With WalletConnect you can connect to almost any existing wallet.": "With WalletConnect you can connect to almost any existing wallet.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.",
    "Connect with your Google Account": "Connect with your Google Account",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.",
    "Connect with Google": "Connect with Google",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.",
    "Connect to your Wallet via Social Connect.": "Connect to your Wallet via Social Connect.",
    "Connect with your Meta Account": "Connect with your Meta Account",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.",
    "Connect with Facebook": "Connect with Facebook",
    "Connect with Metamask": "Connect with Metamask",
    "Connect with Wallet Connect": "Connect with Wallet Connect",
    "Verify your e-mail address": "Verify your e-mail address",
    "The code was sent to your e-mail": "The code was sent to your e-mail",
    "Please enter the code": "Please enter the code",
    "Verify": "Verify",
    "Resend": "Resend",
    "Discard": "Discard",
    "Attending": "Attending",
    "Type": "Type",
    "Start": "Start",
    "End": "End",
    "Details": "Details",
    "Signed up": "Signed up",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "New content is available. Please click here to refresh the page and avoid inconsistencies.",
    "Home": "Home",
    "Already minted in total": "Already minted in total",
    "Already minted / wallet": "Already minted / wallet",
    "Mint for {mintPrice} ETH / each": "Mint for {mintPrice} ETH / each",
    "Paid Transaction": "Paid Transaction",
    "Mint": "Mint",
    "Buy Mint": "Buy Mint",
    "Select an option to pay for this mint": "Select an option to pay for this mint",
    "Pay with Crypto": "Pay with Crypto",
    "We realized that your balance is too low. You need to have at least ": "We realized that your balance is too low. You need to have at least ",
    "Pay with Credit Card": "Pay with Credit Card",
    "Please wait for the payment to be completed": "Please wait for the payment to be completed",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "The transaction has been sent and you will receive a notification in your bowline wallet",
    "No tokens available for minting": "No tokens available for minting",
    "Max tokens minted": "Max tokens minted",
    "Mint will start soon. Stay tuned!": "Mint will start soon. Stay tuned!",
    "Price/NFT": "Price/NFT",
    "Amount you already minted": "Amount you already minted",
    "Current Price": "Current Price",
    "e.g. Voucher Code": "e.g. Voucher Code",
    "Sold out!": "Sold out!",
    "Please visit the": "Please visit the",
    "secondary market.": "secondary market.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
    "Switch to Ethereum network": "Switch to Ethereum network",
    "Switch to Rinkeby network": "Switch to Rinkeby network",
    "Switch to Görli network": "Switch to Görli network",
    "Switch to Polygon network": "Switch to Polygon network",
    "Switch to Mumbai network": "Switch to Mumbai network",
    "Preview": "Preview",
    "Edit": "Edit",
    "Update": "Update",
    "Create": "Create",
    "Update Page": "Update Page",
    "Create Page": "Create Page",
    "Do you want to update this page?": "Do you want to update this page?",
    "Do you want to create this page?": "Do you want to create this page?",
    "Set Title": "Set Title",
    "Set URL": "Set URL",
    "Locale": "Locale",
    "Page Deleted": "Page Deleted",
    "Page was deleted successfully!": "Page was deleted successfully!",
    "Continue": "Continue",
    "Back": "Back",
    "Missing title": "Missing title",
    "Expired": "Expired",
    "Unlock": "Unlock",
    "Please connect your wallet to be able to unlock": "Please connect your wallet to be able to unlock",
    "Welcome to {project}": "Welcome to {project}",
    "{project} asks for your email address": "{project} asks for your email address",
    "{project} requires your email address to continue": "{project} requires your email address to continue",
    "share email anonymised": "share email anonymised",
    "(recommended)": "(recommended)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} will only receive an anonymised email address but bowline will keep track of your real email address.",
    "share email": "share email",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.",
    "don't share email": "don't share email",
    "Email address": "Email address",
    "Your stored email": "Your stored email",
    "Submit": "Submit",
    "By submitting you accept the": "By submitting you accept the",
    "terms of service": "terms of service",
    "terms of service for {projectTitle}": "terms of service for {projectTitle}",
    "Close": "Close",
    "data protection policy": "data protection policy",
    "data protection policy for {projectTitle}": "data protection policy for {projectTitle}",
    "of {projectTitle}.": "of {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.",
    "Share": "Share",
    "Bid for this Product": "Bid for this Product",
    "you must bid at least": "you must bid at least",
    "Enter bid": "Enter bid",
    "Bid can't be higher than current balance": "Bid can't be higher than current balance",
    "Warning: Your bid is lower than the current highest bid": "Warning: Your bid is lower than the current highest bid",
    "Your balance": "Your balance",
    "Place your Bid": "Place your Bid",
    "You cannot place a bid as you have insufficient funds.": "You cannot place a bid as you have insufficient funds.",
    "Please follow the confirmation steps in your wallet.": "Please follow the confirmation steps in your wallet.",
    "Your bid is stored in our database.": "Your bid is stored in our database.",
    "You have successfully placed your bid!": "You have successfully placed your bid!",
    "We will notify you if you are outbid, or if you are the bid winner!": "We will notify you if you are outbid, or if you are the bid winner!",
    " view more": " view more",
    "This is a cached value, the price should soon be loaded from blockchain.": "This is a cached value, the price should soon be loaded from blockchain.",
    "Purchase": "Purchase",
    "Your item purchase was successful": "Your item purchase was successful",
    "Okay": "Okay",
    "Order Summary": "Order Summary",
    "Amount: ": "Amount: ",
    "Total": "Total",
    "Close Modal": "Close Modal",
    "Transaction in Progress": "Transaction in Progress",
    "Please sign allowance": "Please sign allowance",
    "please approve the interaction": "please approve the interaction",
    "Checking your balance": "Checking your balance",
    "checking if your wallet has enough funds to buy this product": "checking if your wallet has enough funds to buy this product",
    "Please sign transaction": "Please sign transaction",
    "Metamask should open and ask you to sign the transaction": "Metamask should open and ask you to sign the transaction",
    "Wait for transaction to be mined": "Wait for transaction to be mined",
    "Depending on network traffic this could take a few minutes": "Depending on network traffic this could take a few minutes",
    "Something went wrong": "Something went wrong",
    "We could not purchase the product!": "We could not purchase the product!",
    "Biling details": "Biling details",
    "First name": "First name",
    "This field is required": "This field is required",
    "Maximum length is 150": "Maximum length is 150",
    "Last name": "Last name",
    "Company name": "Company name",
    "Address": "Address",
    "Minimum length is 5": "Minimum length is 5",
    "Maximum length is 250": "Maximum length is 250",
    "Postal Code": "Postal Code",
    "City": "City",
    "Country": "Country",
    "Summary": "Summary",
    "Products": "Products",
    "Price": "Price",
    "Continue Purchase": "Continue Purchase",
    "Please Sign in with Wallet first before purchasing a product!": "Please Sign in with Wallet first before purchasing a product!",
    "Please connect your wallet first before being able to purchase products": "Please connect your wallet first before being able to purchase products",
    "Click Purchase to complete your checkout.": "Click Purchase to complete your checkout.",
    "Sign in with Wallet": "Sign in with Wallet",
    "Stake All selected": "Stake All selected",
    "Unstake All selected": "Unstake All selected",
    "staked": "staked",
    "Unstake": "Unstake",
    "Stake": "Stake",
    "unclaimed earnings": "unclaimed earnings",
    "Your retrieved earnings, that are ready to claim!": "Your retrieved earnings, that are ready to claim!",
    "pending rewards": "pending rewards",
    "Tokens in the Hole": "Tokens in the Hole",
    "Your total Tokens staked": "Your total Tokens staked",
    "Available Balance": "Available Balance",
    "Your lifetime earnings from staking.": "Your lifetime earnings from staking.",
    "Claim": "Claim",
    "Authorization required": "Authorization required",
    "The provided voucher code is not valid": "The provided voucher code is not valid",
    "The provided voucher code got already used. Each voucher code can be used only once.": "The provided voucher code got already used. Each voucher code can be used only once.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.",
    "Attention:": "Attention:",
    "We can't guarantee the exact order of the transactions.": "We can't guarantee the exact order of the transactions.",
    "An Error occured!": "An Error occured!",
    "show full error message": "show full error message",
    "Processing": "Processing",
    "Success!": "Success!",
    "a content category": "a content category",
    "an unlockable content": "an unlockable content",
    "a whitelist entry": "a whitelist entry",
    "a event": "a event",
    "a token": "a token",
    "created": "created",
    "updated": "updated",
    "minted": "minted",
    "transfered": "transfered",
    "You successfully {action} {resourceType}": "You successfully {action} {resourceType}",
    "ok": "ok",
    "click to get back to overview screen": "click to get back to overview screen",
    "Missing Name": "Missing Name",
    "Select": "Select",
    "Selected": "Selected",
    "Transfer Token": "Transfer Token",
    "Receiver": "Receiver",
    "Not a valid address!": "Not a valid address!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ You will transfer an nft to {address}. This operation can not be undone",
    "Transfer this NFT": "Transfer this NFT",
    "Purchase in Progress": "Purchase in Progress",
    "Signing with your wallet": "Signing with your wallet",
    "Please follow your wallet instructions to confirm the transaction.": "Please follow your wallet instructions to confirm the transaction.",
    "You confirmed the transaction with your wallet.": "You confirmed the transaction with your wallet.",
    "Transaction is sent to the blockchain": "Transaction is sent to the blockchain",
    "After signing the transaction it is send to the blockchain.": "After signing the transaction it is send to the blockchain.",
    "Transaction is being processed": "Transaction is being processed",
    "Please check your wallets transaction history for your actual transaction status!": "Please check your wallets transaction history for your actual transaction status!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.",
    "Select Tokens for Voting": "Select Tokens for Voting",
    "Select Token for Unlocking": "Select Token for Unlocking",
    "Select {n} token from these": "Select {n} token from these",
    "And": "And",
    "Or": "Or",
    "Sorry you dont have any matching Token for unlocking": "Sorry you dont have any matching Token for unlocking",
    "Please fill out those inputs to unlock": "Please fill out those inputs to unlock",
    "Entered value does not match email format": "Entered value does not match email format",
    "Sign Up": "Sign Up",
    "Vote": "Vote",
    "Email": "Email",
    "Change password": "Change password",
    "Insert your current password and the new password": "Insert your current password and the new password",
    "Current Password": "Current Password",
    "Password": "Password",
    "Save": "Save",
    "tokens": "tokens",
    "community event": "community event",
    "partner event": "partner event",
    "conference": "conference",
    "irl meetup": "irl meetup",
    "irl party": "irl party",
    "virtual meetup": "virtual meetup",
    "discord event": "discord event",
    "ask me anything": "ask me anything",
    "There are no votings options available": "There are no votings options available",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Please connect your wallet to be able to vote",
    "Your Vote has been sent successfully": "Your Vote has been sent successfully",
    "Reveal": "Reveal",
    "get more Info": "get more Info",
    "This vote has ended. Thanks!": "This vote has ended. Thanks!",
    "Wallet Changed": "Wallet Changed",
    "It looks like you changed your wallet, would you like to connect your wallet?": "It looks like you changed your wallet, would you like to connect your wallet?",
    "Export Wallet": "Export Wallet",
    "(A QR code will be generated for {n} seconds)": "(A QR code will be generated for {n} seconds)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.",
    "Copy Private Key": "Copy Private Key",
    "please complete your profile": "please complete your profile",
    "* means required": "* means required",
    "Please connect to your wallet first.": "Please connect to your wallet first.",
    "Contract Address": "Contract Address",
    "Add my Wallet to this Allowlist": "Add my Wallet to this Allowlist",
    "Congratulations!": "Congratulations!",
    "You've been added to the Allowlist!": "You've been added to the Allowlist!",
    "Do you want to receive notifications on your email about this listing?": "Do you want to receive notifications on your email about this listing?",
    "Wallet Address": "Wallet Address",
    "You are already part of this allowlist!": "You are already part of this allowlist!",
    "To mint this NFT please connect your wallet": "To mint this NFT please connect your wallet",
    "Password Unlock": "Password Unlock",
    "This page is password protected.": "This page is password protected.",
    "Check Password": "Check Password",
    "No Access": "No Access",
    "List of enabled Modules": "List of enabled Modules",
    "show": "show",
    "Events": "Events",
    "Unlockables": "Unlockables",
    "Votings": "Votings",
    "Allowlist": "Allowlist",
    "Partner Deals": "Partner Deals",
    "Token Search": "Token Search",
    "My Tokens": "My Tokens",
    "go to": "go to",
    "Minting": "Minting",
    "Event Type": "Event Type",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Sign up for this event": "Sign up for this event",
    "This event has ended.": "This event has ended.",
    "There was a problem showing this event. Please contact support on discord": "There was a problem showing this event. Please contact support on discord",
    "There was a problem signing up for this event. Please contact support on discord": "There was a problem signing up for this event. Please contact support on discord",
    "Successfully Unlocked!": "Successfully Unlocked!",
    "The event has been unlocked.": "The event has been unlocked.",
    "Upcoming": "Upcoming",
    "Completed": "Completed",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "There was a problem listing all the unlockable contents. Please contact support on discord",
    "Page not found": "Page not found",
    "You are not authorized to see this page!": "You are not authorized to see this page!",
    "Please connect your wallet first": "Please connect your wallet first",
    "Please sign in with Wallet first": "Please sign in with Wallet first",
    "You need to own one of our NFTs to view this page.": "You need to own one of our NFTs to view this page.",
    "Connect your wallet to proceed.": "Connect your wallet to proceed.",
    "You didn't purchase our NFT yet? Click here to proceed.": "You didn't purchase our NFT yet? Click here to proceed.",
    "Please click Sign in with Ethereum to verify your NFT Ownership.": "Please click Sign in with Ethereum to verify your NFT Ownership.",
    "If you have already minted please wait a couple minutes in order for the transaction to be completed and ": "If you have already minted please wait a couple minutes in order for the transaction to be completed and ",
    "click here ": "click here ",
    "to check your holder status.": "to check your holder status.",
    "Become a Holder": "Become a Holder",
    "Minting Section": "Minting Section",
    "Please connect your Wallet": "Please connect your Wallet",
    "Sign in with your crypto wallet or Google account to mint NFTs.": "Sign in with your crypto wallet or Google account to mint NFTs.",
    "Terms and Conditions": "Terms and Conditions",
    "Successfully Minted": "Successfully Minted",
    "The mint was successful.": "The mint was successful.",
    "You successfully minted the {tokenTitle} Token.": "You successfully minted the {tokenTitle} Token.",
    "You can see it in your Wallet": "You can see it in your Wallet",
    "Check your transaction on Polygonscan": "Check your transaction on Polygonscan",
    "Check your transaction on Etherscan": "Check your transaction on Etherscan",
    "Validating Voucher Code": "Validating Voucher Code",
    "We are validating your voucher code.": "We are validating your voucher code.",
    "Generating Airdrop": "Generating Airdrop",
    "The token is going to be airdropped to your wallet.": "The token is going to be airdropped to your wallet.",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.",
    "Something went wrong minting your token": "Something went wrong minting your token",
    "We could not mint your Token!": "We could not mint your Token!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.",
    "Something went wrong validating": "Something went wrong validating",
    "We could not validate your code!": "We could not validate your code!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.",
    "There was a problem listing all the partner deals. Please contact support on discord": "There was a problem listing all the partner deals. Please contact support on discord",
    "Your partner deal has been unlocked": "Your partner deal has been unlocked",
    "There was a problem unlocking this Partner Deal. Please contact support on discord": "There was a problem unlocking this Partner Deal. Please contact support on discord",
    "Static Page": "Static Page",
    "Loading...": "Loading...",
    "Transaction Successful": "Transaction Successful",
    "Your tokens have been successfully minted": "Your tokens have been successfully minted",
    "Missing name": "Missing name",
    "Check out this Token": "Check out this Token",
    "Description": "Description",
    "Owner": "Owner",
    "(You own this token)": "(You own this token)",
    "Transfer to another Wallet": "Transfer to another Wallet",
    "Traits": "Traits",
    "Blockchain": "Blockchain",
    "Token ID": "Token ID",
    "Token Standard": "Token Standard",
    "Check on Marketplaces": "Check on Marketplaces",
    "Rarible": "Rarible",
    "Related": "Related",
    "Unlockable Contents": "Unlockable Contents",
    "Token Transferred": "Token Transferred",
    "Your token has been successfully transferred": "Your token has been successfully transferred",
    "Filter": "Filter",
    "Filter Tokens": "Filter Tokens",
    "Show {n} Tokens": "Show {n} Tokens",
    "Secret Description": "Secret Description",
    "Secret Files": "Secret Files",
    "There was a problem showing this unlockable content. Please contact support on discord": "There was a problem showing this unlockable content. Please contact support on discord",
    "There was a problem unlocking this unlockable content. Please contact support on discord": "There was a problem unlocking this unlockable content. Please contact support on discord",
    "The content has been unlocked successfully": "The content has been unlocked successfully",
    "Filter by Category": "Filter by Category",
    "All": "All",
    "There are no unlockable contents yet": "There are no unlockable contents yet",
    "You will receive a mail with a link to the password reset page.": "You will receive a mail with a link to the password reset page.",
    " ⚠ This app will be sunset on {dueFourWeeks} ⚠": " ⚠ This app will be sunset on {dueFourWeeks} ⚠",
    " ⚠ This app is currently deactivated ⚠ ": " ⚠ This app is currently deactivated ⚠ ",
    "immediate": "immediate",
    "after vote": "after vote",
    "after end": "after end",
    "Reveal Type": "Reveal Type",
    "There was a problem showing this voting. Please contact support on discord": "There was a problem showing this voting. Please contact support on discord",
    "There was a problem unlocking this voting. Please contact support on discord": "There was a problem unlocking this voting. Please contact support on discord",
    "Your Vote has been unlocked successfully": "Your Vote has been unlocked successfully",
    "There are no votings yet": "There are no votings yet",
    "Open": "Open"
  },
  "es": {
    "Wallet": "Cartera",
    "New alerts": "Nuevas alertas",
    "Not signed in with ethereum": "No has iniciado sesión con Ethereum",
    "Bowline Wallet": "Cartera Bowline",
    "Account": "Cuenta",
    "Balance": "Saldo",
    "Signing Request": "Solicitud de firma",
    "Cancel": "Cancelar",
    "Sign": "Firmar",
    "You are not signed in with ethereum yet": "Aún no has iniciado sesión con Ethereum",
    "You are signed in with ethereum": "Has iniciado sesión con Ethereum",
    "Copy address": "Copiar dirección",
    "Tokens": "Tokens",
    "Settings": "Configuración",
    "Activity": "Actividad",
    "Please use \"Sign in with Ethereum\" first.": "Por favor, usa \"Iniciar sesión con Ethereum\" primero.",
    "See above": "Ver arriba",
    "Delete Account": "Eliminar cuenta",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Para eliminar tu cuenta, por favor contáctanos: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "Please \"Sign in with Ethereum\" first.": "Por favor, utiliza \"Iniciar sesión con Ethereum\" primero.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "No Activity yet in this session": "",
    "Recent Signatures": "",
    "Transactions": "",
    "Disconnect": "",
    "Your Tokens related to this project": "",
    "Reload Tokens": "",
    "You do not have any Token in Wallet for this project": "",
    "Reload": "",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "",
    "or": "",
    "Connect to Wallet": "",
    "close": "",
    "Select a Wallet": "",
    "Metamask": "",
    "Ledger": "",
    "Coinbase Wallet": "",
    "Wallet Connect": "",
    "Social Connect": "",
    "Google": "",
    "Facebook": "",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "How to connect?": "",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "",
    "I do have a wallet.": "",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "",
    "No wallet yet?": "",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "",
    "Connect with Coinbase Wallet": "",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Want to learn more about this wallet?": "",
    "Learn how to install and setup this wallet on": "",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "",
    "Connect with Ledger": "",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "",
    "Connect with MetaMask": "",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Please use your existing MetaMask wallet.": "",
    "Connect with your preferred wallet": "",
    "With WalletConnect you can connect to almost any existing wallet.": "",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "",
    "Connect with your Google Account": "",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "",
    "Connect with Google": "",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "",
    "Connect to your Wallet via Social Connect.": "",
    "Connect with your Meta Account": "",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "",
    "Connect with Facebook": "",
    "Connect with Metamask": "",
    "Connect with Wallet Connect": "",
    "Attending": "",
    "Type": "",
    "Start": "",
    "End": "",
    "Details": "Detalles",
    "Signed up": "",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "",
    "Home": "",
    "Already minted in total": "",
    "Already minted / wallet": "",
    "Mint for {mintPrice} ETH / each": "",
    "Paid Transaction": "Transacción pagada",
    "Mint": "Menta",
    "Buy Mint": "Comprar Casa de la Moneda",
    "Select an option to pay for this mint": "Seleccione una opción para pagar esta menta",
    "Pay with Crypto": "Pagar con criptomonedas",
    "We realized that your balance is too low. You need to have at least ": "Nos hemos dado cuenta de que tu saldo es demasiado bajo. Necesita tener al menos ",
    "Pay with Credit Card": "Pagar con tarjeta de crédito",
    "Please wait for the payment to be completed": "Espere a que se complete el pago",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "La transacción ha sido enviada y recibirá una notificación en su monedero bowline",
    "No tokens available for minting": "No hay fichas disponibles para acuñar",
    "Max tokens minted": "Número máximo de fichas acuñadas",
    "Mint will start soon. Stay tuned!": "La Casa de la Moneda empezará pronto. ¡Esté atento!",
    "Price/NFT": "",
    "Amount you already minted": "",
    "Current Price": "",
    "e.g. Voucher Code": "p. ej. Código del vale",
    "Sold out!": "",
    "Please visit the": "",
    "secondary market.": "",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "",
    "Switch to Ethereum network": "",
    "Switch to Rinkeby network": "",
    "Switch to Görli network": "",
    "Switch to Polygon network": "",
    "Switch to Mumbai network": "",
    "Preview": "",
    "Edit": "",
    "Update": "",
    "Create": "",
    "Update Page": "",
    "Create Page": "",
    "Do you want to update this page?": "",
    "Do you want to create this page?": "",
    "Set Title": "",
    "Set URL": "",
    "Locale": "",
    "Page Deleted": "",
    "Page was deleted successfully!": "",
    "Continue": "",
    "Back": "",
    "Missing title": "Falta el título",
    "Expired": "",
    "Unlock": "Desbloquear",
    "Please connect your wallet to be able to unlock": "",
    "Welcome to {project}": "",
    "{project} asks for your email address": "",
    "{project} requires your email address to continue": "",
    "share email anonymised": "",
    "(recommended)": "",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "",
    "share email": "",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "",
    "don't share email": "",
    "Email address": "",
    "Your stored email": "",
    "Submit": "",
    "By submitting you accept the": "",
    "terms of service": "",
    "terms of service for {projectTitle}": "",
    "Close": "Cerrar",
    "data protection policy": "",
    "data protection policy for {projectTitle}": "",
    "of {projectTitle}.": "",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Share": "",
    "Bid for this Product": "",
    "you must bid at least": "",
    "Enter bid": "",
    "Bid can't be higher than current balance": "",
    "Warning: Your bid is lower than the current highest bid": "",
    "Your balance": "",
    "Place your Bid": "",
    "You cannot place a bid as you have insufficient funds.": "",
    "Please follow the confirmation steps in your wallet.": "",
    "Your bid is stored in our database.": "",
    "You have successfully placed your bid!": "",
    "We will notify you if you are outbid, or if you are the bid winner!": "",
    " view more": "",
    "This is a cached value, the price should soon be loaded from blockchain.": "",
    "Purchase": "",
    "Your item purchase was successful": "",
    "Okay": "Vale",
    "Order Summary": "",
    "Amount: ": "",
    "Total": "",
    "Close Modal": "Cerrar Modal",
    "Transaction in Progress": "Transacción en curso",
    "Please sign allowance": "",
    "please approve the interaction": "",
    "Checking your balance": "",
    "checking if your wallet has enough funds to buy this product": "",
    "Please sign transaction": "",
    "Metamask should open and ask you to sign the transaction": "",
    "Wait for transaction to be mined": "",
    "Depending on network traffic this could take a few minutes": "",
    "Something went wrong": "",
    "We could not purchase the product!": "",
    "Biling details": "",
    "First name": "",
    "This field is required": "",
    "Maximum length is 150": "",
    "Last name": "",
    "Company name": "",
    "Address": "",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "",
    "City": "",
    "Country": "",
    "Summary": "",
    "Products": "",
    "Price": "",
    "Continue Purchase": "",
    "Please Sign in with Ethereum first before purchasing a product!": "",
    "Please connect your wallet first before being able to purchase products": "",
    "Click Purchase to complete your checkout.": "",
    "Sign In with Ethereum": "",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "",
    "Your retrieved earnings, that are ready to claim!": "",
    "pending rewards": "",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "",
    "Your lifetime earnings from staking.": "",
    "Claim": "",
    "Authorization required": "Se requiere autorización",
    "The provided voucher code is not valid": "",
    "The provided voucher code got already used. Each voucher code can be used only once.": "",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "",
    "An Error occured!": "",
    "show full error message": "",
    "Processing": "",
    "Success!": "",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "",
    "a token": "",
    "created": "",
    "updated": "",
    "minted": "",
    "transfered": "",
    "You successfully {action} {resourceType}": "",
    "ok": "ok",
    "click to get back to overview screen": "",
    "Missing Name": "",
    "Select": "",
    "Selected": "",
    "Transfer Token": "",
    "Receiver": "",
    "Not a valid address!": "",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": "",
    "Transfer this NFT": "",
    "Purchase in Progress": "",
    "Signing with your wallet": "",
    "Please follow your wallet instructions to confirm the transaction.": "",
    "You confirmed the transaction with your wallet.": "",
    "Transaction is sent to the blockchain": "",
    "After signing the transaction it is send to the blockchain.": "",
    "Transaction is being processed": "Se está procesando la transacción",
    "Please check your wallets transaction history for your actual transaction status!": "",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Puede cerrar este modal en cualquier momento - no afecta a su transacción. Cerraremos el modal por ti una vez que veamos que la transacción está minada.",
    "Select Tokens for Voting": "",
    "Select Token for Unlocking": "",
    "Select {n} token from these": "",
    "And": "",
    "Or": "",
    "Sorry you dont have any matching Token for unlocking": "",
    "Please fill out those inputs to unlock": "",
    "Entered value does not match email format": "",
    "Sign Up": "",
    "Vote": "",
    "Email": "",
    "Change password": "Cambiar contraseña",
    "Insert your current password and the new password": "",
    "Current Password": "",
    "Password": "",
    "Save": "",
    "tokens": "fichas",
    "community event": "",
    "partner event": "",
    "conference": "",
    "irl meetup": "",
    "irl party": "",
    "virtual meetup": "",
    "discord event": "",
    "ask me anything": "",
    "There are no votings options available": "No hay opciones de votación disponibles",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Conecte su monedero para poder votar",
    "Your Vote has been sent successfully": "Su voto se ha enviado correctamente",
    "Reveal": "",
    "get more Info": "",
    "This vote has ended. Thanks!": "",
    "Wallet Changed": "",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "",
    "(A QR code will be generated for {n} seconds)": "",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "",
    "Copy Private Key": "",
    "please complete your profile": "",
    "* means required": "",
    "Please connect to your wallet first.": "",
    "Contract Address": "Dirección del contrato",
    "Add my Wallet to this Allowlist": "",
    "Congratulations!": "",
    "You've been added to the Allowlist!": "",
    "Do you want to receive notifications on your email about this listing?": "",
    "Wallet Address": "",
    "You are already part of this allowlist!": "",
    "To mint this NFT please connect your wallet": "Para acuñar este NFT conecte su monedero",
    "for": "para",
    "You are not signed in with your Wallet yet": "Aún no has iniciado sesión con tu Wallet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Se le pedirá que firme un mensaje para generar una conexión de inicio de sesión segura para su monedero. No tendremos acceso a tus fondos, NFTs ni realizaremos ninguna acción con esta información.",
    "Wrong Network": "Red equivocada",
    "You are currently connected to the wrong network": "Está conectado a una red incorrecta",
    "Please switch to the correct network in your wallet to continue.": "Por favor, cambia a la red correcta en tu monedero para continuar.",
    "Switch Network": "Red de conmutación",
    "Password Unlock": "Desbloquear contraseña",
    "This page is password protected.": "Esta página está protegida por contraseña.",
    "Check Password": "Comprobar contraseña",
    "No Access": "Sin acceso",
    "List of enabled Modules": "Lista de módulos habilitados",
    "show": "mostrar",
    "Events": "Eventos",
    "Unlockables": "Desbloqueables",
    "Votings": "Votaciones",
    "Allowlist": "Lista de permisos",
    "Partner Deals": "Ofertas para socios",
    "Token Search": "Búsqueda de fichas",
    "My Tokens": "Mis fichas",
    "go to": "ir a",
    "Minting": "Acuñación",
    "Raffles": "Sorteos",
    "You are registered for this event": "Está inscrito en este evento",
    "Event Type": "Tipo de evento",
    "Start Date": "Fecha de inicio",
    "End Date": "Fecha final",
    "Sign up for this event": "Inscríbete en este evento",
    "This event has ended.": "Este evento ha finalizado.",
    "Unlocking Content": "Desbloquear contenidos",
    "Successfully Unlocked": "Desbloqueado con éxito",
    "You have signed up for this event": "Te has inscrito en este evento",
    "View event": "Ver evento",
    "There was a problem while signing up for this event.": "Se ha producido un problema al inscribirse en este evento.",
    "There was a problem showing this event. Please contact support on discord": "Se ha producido un problema al mostrar este evento. Póngase en contacto con el servicio de asistencia en Discord",
    "All": "Todos",
    "Filter by Tribe": "Filtrar por tribu",
    "Upcoming": "Próximamente",
    "Completed": "Completado",
    "For Me": "Para mí",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "Ha habido un problema al listar todos los contenidos desbloqueables. Póngase en contacto con el servicio de asistencia en Discord",
    "Page not found": "Página no encontrada",
    "You are not authorized to see this page!": "¡No está autorizado a ver esta página!",
    "Please connect your wallet first": "Conecte primero su monedero",
    "Please sign in with Wallet first": "Inicia sesión primero con Wallet",
    "You need to own one of our NFTs to view this page.": "Debe poseer uno de nuestros NFT para ver esta página.",
    "Connect your wallet to proceed.": "Conecta tu monedero para continuar.",
    "Please click Sign in with Ethereum to verify your NFT Ownership.": "Haga clic en Iniciar sesión con Ethereum para verificar su propiedad de NFT.",
    "If you have already minted please wait a couple minutes in order for the transaction to be completed and ": "Si ya ha acuñado, espere un par de minutos para que se complete la transacción y ",
    "click here ": "pulse aquí ",
    "to check your holder status.": "para comprobar el estado de su titular.",
    "Become a Holder": "Hágase titular",
    "Minting Section": "Sección de acuñación",
    "Please connect your Wallet": "Conecte su monedero",
    "Sign in with your crypto wallet or Google account to mint NFTs.": "Inicia sesión con tu criptocartera o cuenta de Google para acuñar NFT.",
    "Terms and Conditions": "Condiciones generales",
    "Successfully Minted": "Acuñado con éxito",
    "The mint was successful.": "La menta tuvo éxito.",
    "You successfully minted the {tokenTitle} Token.": "Has acuñado con éxito el Token {tokenTitle}.",
    "You can see it in your Wallet": "Puede verlo en su Cartera",
    "Check your transaction on Polygonscan": "Compruebe su transacción en Polygonscan",
    "Check your transaction on Etherscan": "Compruebe su transacción en Etherscan",
    "Validating Voucher Code": "Validación del código promocional",
    "We are validating your voucher code.": "Estamos validando su código de vale.",
    "Generating Airdrop": "Generar Airdrop",
    "The token is going to be airdropped to your wallet.": "La ficha será enviada a tu cartera.",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "La transacción fue enviada a la blockchain y estamos esperando el resultado. Por favor, sea paciente, esto puede tomar algún tiempo.",
    "Something went wrong minting your token": "Algo salió mal al acuñar tu ficha",
    "We could not mint your Token!": "¡No pudimos acuñar su ficha!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Desafortunadamente algo salió mal al enviar su transacción. Por favor, inténtelo de nuevo. Si el error persiste, póngase en contacto con nosotros en support@bowline.app e incluya el mensaje de error completo.",
    "Something went wrong validating": "Algo salió mal al validar",
    "We could not validate your code!": "¡No hemos podido validar su código!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Lamentablemente no hemos podido validar su código de vale. Por favor, inténtelo de nuevo. Si el error persiste, póngase en contacto con nosotros en support@bowline.app e incluya el mensaje de error completo.",
    "There was a problem listing all the partner deals. Please contact support on discord": "Ha habido un problema al listar todas las ofertas de socios. Póngase en contacto con el servicio de asistencia en Discord",
    "You are registered for this raffle": "Estás inscrito en este sorteo",
    "Raffle Type": "Tipo de sorteo",
    "Sign up for this raffle": "Apúntate a este sorteo",
    "This raffle has ended.": "Este sorteo ha terminado.",
    "Raffle Winners": "Ganadores del sorteo",
    "You have signed up for this raffle": "Te has apuntado a este sorteo",
    "View raffle": "Ver sorteo",
    "There was a problem while signing up for this raffle.": "Ha habido un problema al inscribirse en este sorteo.",
    "There was a problem showing this raffle. Please contact support on discord": "Se ha producido un problema al mostrar este sorteo. Póngase en contacto con el servicio de asistencia en Discord",
    "Static Page": "Página estática",
    "Loading...": "Cargando…",
    "Transaction Successful": "Transacción realizada con éxito",
    "Your tokens have been successfully minted": "Sus fichas han sido acuñadas con éxito",
    "Missing name": "Falta el nombre",
    "Check out this Token": "Echa un vistazo a esta ficha",
    "Description": "Descripción",
    "Owner": "Propietario",
    "(You own this token)": "(Esta ficha es suya)",
    "Transfer to another Wallet": "Transferencia a otra Cartera",
    "Traits": "Rasgos",
    "Blockchain": "Blockchain",
    "Token ID": "Ficha ID",
    "Token Standard": "Ficha estándar",
    "Check on Marketplaces": "Comprobar en los mercados",
    "Rarible": "Rarible",
    "Related": "Relacionado",
    "Unlockable Contents": "Contenidos desbloqueables",
    "Token Transferred": "Ficha transferida",
    "Your token has been successfully transferred": "Su ficha se ha transferido correctamente",
    "Filter": "Filtro",
    "Filter Tokens": "Filtrar fichas",
    "Show {n} Tokens": "Mostrar {n} Fichas",
    "The content has been unlocked successfully": "El contenido se ha desbloqueado correctamente",
    "View content": "Ver el contenido",
    "There was a problem unlocking this unlockable content.": "Ha habido un problema al desbloquear este contenido desbloqueable.",
    "Secret Description": "Descripción secreta",
    "Secret Files": "Archivos secretos",
    "There was a problem showing this unlockable content. Please contact support on discord": "Ha habido un problema al mostrar este contenido desbloqueable. Póngase en contacto con el servicio de asistencia en Discord",
    "Filter by Category": "Filtrar por categoría",
    "There are no unlockable contents yet": "Aún no hay contenidos desbloqueables",
    "You will receive a mail with a link to the password reset page.": "Recibirás un correo electrónico con un enlace a la página de restablecimiento de contraseña.",
    " ⚠ This app will be sunset on {dueFourWeeks} ⚠": " ⚠ Esta aplicación se pondrá al sol el {dueFourWeeks} ⚠",
    " ⚠ This app is currently deactivated ⚠ ": " ⚠ Esta aplicación está actualmente desactivada ⚠ ",
    "immediate": "inmediato",
    "after vote": "tras la votación",
    "after end": "después del final",
    "Reveal Type": "Tipo de revelación",
    "Voting": "Votar",
    "Successfully Voted": "Votado con éxito",
    "There was a problem while sending your vote. Please try again": "Se ha producido un problema al enviar tu voto. Vuelva a intentarlo",
    "There was a problem showing this voting. Please contact support on discord": "Ha habido un problema al mostrar esta votación. Póngase en contacto con el servicio de asistencia en Discord",
    "There are no votings yet": "Aún no hay votaciones",
    "Open": "Abrir"
  },
  "fr": {
    "Wallet": "Portefeuille",
    "New alerts": "Nouvelles alertes",
    "Not signed in with ethereum": "Non connecté avec Ethereum",
    "Bowline Wallet": "Portefeuille Bowline",
    "Account": "Compte",
    "Balance": "Solde",
    "Signing Request": "Demande de signature",
    "Cancel": "Annuler",
    "Sign": "Signer",
    "You are not signed in with ethereum yet": "Vous n'êtes pas encore connecté avec Ethereum",
    "You are signed in with ethereum": "Vous êtes connecté avec Ethereum",
    "Copy address": "Copier l'adresse",
    "Tokens": "Jetons",
    "Settings": "Paramètres",
    "Activity": "Activité",
    "Please use \"Sign in with Ethereum\" first.": "Veuillez utiliser \"Se connecter avec Ethereum\" en premier.",
    "See above": "Voir ci-dessus",
    "Delete Account": "Supprimer le compte",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Pour supprimer votre compte, veuillez nous contacter : <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "Please \"Sign in with Ethereum\" first.": "Veuillez utiliser \"Se connecter avec Ethereum\" en premier.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "No Activity yet in this session": "",
    "Recent Signatures": "",
    "Transactions": "",
    "Disconnect": "",
    "Your Tokens related to this project": "",
    "Reload Tokens": "",
    "You do not have any Token in Wallet for this project": "",
    "Reload": "",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "",
    "or": "",
    "Connect to Wallet": "",
    "close": "",
    "Select a Wallet": "",
    "Metamask": "",
    "Ledger": "",
    "Coinbase Wallet": "",
    "Wallet Connect": "",
    "Social Connect": "",
    "Google": "",
    "Facebook": "",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "How to connect?": "",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "",
    "I do have a wallet.": "",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "",
    "No wallet yet?": "",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "",
    "Connect with Coinbase Wallet": "",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Want to learn more about this wallet?": "",
    "Learn how to install and setup this wallet on": "",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "",
    "Connect with Ledger": "",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "",
    "Connect with MetaMask": "",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Please use your existing MetaMask wallet.": "",
    "Connect with your preferred wallet": "",
    "With WalletConnect you can connect to almost any existing wallet.": "",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "",
    "Connect with your Google Account": "",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "",
    "Connect with Google": "",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "",
    "Connect to your Wallet via Social Connect.": "",
    "Connect with your Meta Account": "",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "",
    "Connect with Facebook": "",
    "Connect with Metamask": "",
    "Connect with Wallet Connect": "",
    "Attending": "",
    "Type": "",
    "Start": "",
    "End": "",
    "Details": "Détails",
    "Signed up": "",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "",
    "Home": "",
    "Already minted in total": "",
    "Already minted / wallet": "",
    "Mint for {mintPrice} ETH / each": "",
    "Paid Transaction": "Transaction payée",
    "Mint": "Mint",
    "Buy Mint": "Acheter de la Mina",
    "Select an option to pay for this mint": "Sélectionnez une option pour payer cette mini",
    "Pay with Crypto": "Payer avec des crypto-monnaies",
    "We realized that your balance is too low. You need to have at least ": "Nous avons constaté que votre solde est trop faible. Vous devez avoir au moins ",
    "Pay with Credit Card": "Payer par carte de crédit",
    "Please wait for the payment to be completed": "Veuillez attendre que le paiement soit effectué",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "La transaction a été envoyée et vous recevrez une notification dans votre Wallet Bowline",
    "No tokens available for minting": "Pas de tokens disponibles pour la maintint",
    "Max tokens minted": "Nombre maximal de tokens minted",
    "Mint will start soon. Stay tuned!": "L’opération Mint débutera bientôt. Restez à l’écoute !",
    "Price/NFT": "",
    "Amount you already minted": "",
    "Current Price": "",
    "e.g. Voucher Code": "ex. code de bon d’achat",
    "Sold out!": "",
    "Please visit the": "",
    "secondary market.": "",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "",
    "Switch to Ethereum network": "",
    "Switch to Rinkeby network": "",
    "Switch to Görli network": "",
    "Switch to Polygon network": "",
    "Switch to Mumbai network": "",
    "Preview": "",
    "Edit": "",
    "Update": "",
    "Create": "",
    "Update Page": "",
    "Create Page": "",
    "Do you want to update this page?": "",
    "Do you want to create this page?": "",
    "Set Title": "",
    "Set URL": "",
    "Locale": "",
    "Page Deleted": "",
    "Page was deleted successfully!": "",
    "Continue": "",
    "Back": "",
    "Missing title": "Titre manquant",
    "Expired": "",
    "Unlock": "Déverrouiller",
    "Please connect your wallet to be able to unlock": "",
    "Welcome to {project}": "",
    "{project} asks for your email address": "",
    "{project} requires your email address to continue": "",
    "share email anonymised": "",
    "(recommended)": "",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "",
    "share email": "",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "",
    "don't share email": "",
    "Email address": "",
    "Your stored email": "",
    "Submit": "",
    "By submitting you accept the": "",
    "terms of service": "",
    "terms of service for {projectTitle}": "",
    "Close": "Fermer",
    "data protection policy": "",
    "data protection policy for {projectTitle}": "",
    "of {projectTitle}.": "",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Share": "",
    "Bid for this Product": "",
    "you must bid at least": "",
    "Enter bid": "",
    "Bid can't be higher than current balance": "",
    "Warning: Your bid is lower than the current highest bid": "",
    "Your balance": "",
    "Place your Bid": "",
    "You cannot place a bid as you have insufficient funds.": "",
    "Please follow the confirmation steps in your wallet.": "",
    "Your bid is stored in our database.": "",
    "You have successfully placed your bid!": "",
    "We will notify you if you are outbid, or if you are the bid winner!": "",
    " view more": "",
    "This is a cached value, the price should soon be loaded from blockchain.": "",
    "Purchase": "",
    "Your item purchase was successful": "",
    "Okay": "Okay",
    "Order Summary": "",
    "Amount: ": "",
    "Total": "",
    "Close Modal": "Fermer la fenêtre modale",
    "Transaction in Progress": "Transaction en cours",
    "Please sign allowance": "",
    "please approve the interaction": "",
    "Checking your balance": "",
    "checking if your wallet has enough funds to buy this product": "",
    "Please sign transaction": "",
    "Metamask should open and ask you to sign the transaction": "",
    "Wait for transaction to be mined": "",
    "Depending on network traffic this could take a few minutes": "",
    "Something went wrong": "",
    "We could not purchase the product!": "",
    "Biling details": "",
    "First name": "",
    "This field is required": "",
    "Maximum length is 150": "",
    "Last name": "",
    "Company name": "",
    "Address": "",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "",
    "City": "",
    "Country": "",
    "Summary": "",
    "Products": "",
    "Price": "",
    "Continue Purchase": "",
    "Please Sign in with Ethereum first before purchasing a product!": "",
    "Please connect your wallet first before being able to purchase products": "",
    "Click Purchase to complete your checkout.": "",
    "Sign In with Ethereum": "",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "",
    "Your retrieved earnings, that are ready to claim!": "",
    "pending rewards": "",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "",
    "Your lifetime earnings from staking.": "",
    "Claim": "",
    "Authorization required": "Autorisation requise",
    "The provided voucher code is not valid": "",
    "The provided voucher code got already used. Each voucher code can be used only once.": "",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "",
    "An Error occured!": "",
    "show full error message": "",
    "Processing": "",
    "Success!": "",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "",
    "a token": "",
    "created": "",
    "updated": "",
    "minted": "",
    "transfered": "",
    "You successfully {action} {resourceType}": "",
    "ok": "ok",
    "click to get back to overview screen": "",
    "Missing Name": "",
    "Select": "",
    "Selected": "",
    "Transfer Token": "",
    "Receiver": "",
    "Not a valid address!": "",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": "",
    "Transfer this NFT": "",
    "Purchase in Progress": "",
    "Signing with your wallet": "",
    "Please follow your wallet instructions to confirm the transaction.": "",
    "You confirmed the transaction with your wallet.": "",
    "Transaction is sent to the blockchain": "",
    "After signing the transaction it is send to the blockchain.": "",
    "Transaction is being processed": "La transaction est en cours de traitement",
    "Please check your wallets transaction history for your actual transaction status!": "",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Vous pouvez fermer cette fenêtre à tout moment - cela n’affecte pas votre transaction. Nous fermerons la fenêtre modale pour vous une fois que nous aurons constaté que la transaction est minée.",
    "Select Tokens for Voting": "",
    "Select Token for Unlocking": "",
    "Select {n} token from these": "",
    "And": "",
    "Or": "",
    "Sorry you dont have any matching Token for unlocking": "",
    "Please fill out those inputs to unlock": "",
    "Entered value does not match email format": "",
    "Sign Up": "",
    "Vote": "",
    "Email": "",
    "Change password": "Modifier le mot de passe",
    "Insert your current password and the new password": "",
    "Current Password": "",
    "Password": "",
    "Save": "",
    "tokens": "tokens",
    "community event": "",
    "partner event": "",
    "conference": "",
    "irl meetup": "",
    "irl party": "",
    "virtual meetup": "",
    "discord event": "",
    "ask me anything": "",
    "There are no votings options available": "Il n’y a pas d’options de vote disponibles",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Veuillez connecter votre Wallet pour pouvoir voter",
    "Your Vote has been sent successfully": "Votre vote a été envoyé avec succès",
    "Reveal": "",
    "get more Info": "",
    "This vote has ended. Thanks!": "",
    "Wallet Changed": "",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "",
    "(A QR code will be generated for {n} seconds)": "",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "",
    "Copy Private Key": "",
    "please complete your profile": "",
    "* means required": "",
    "Please connect to your wallet first.": "",
    "Contract Address": "Adresse du contrat",
    "Add my Wallet to this Allowlist": "",
    "Congratulations!": "",
    "You've been added to the Allowlist!": "",
    "Do you want to receive notifications on your email about this listing?": "",
    "Wallet Address": "",
    "You are already part of this allowlist!": "",
    "To mint this NFT please connect your wallet": "Pour mini ce NFT, veuillez connecter votre Wallet",
    "for": "pour",
    "You are not signed in with your Wallet yet": "Vous n’êtes pas encore connecté avec votre Wallet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Il vous est demandé de signer un message afin de générer une connexion sécurisée pour votre Wallet. Nous n’aurons pas accès à vos fonds, ni à vos NFT, et nous n’effectuerons aucune action avec ces informations.",
    "Wrong Network": "Mauvais réseau",
    "You are currently connected to the wrong network": "Vous êtes actuellement connecté au mauvais réseau",
    "Please switch to the correct network in your wallet to continue.": "Veuillez passer au réseau correct dans votre Wallet pour continuer.",
    "Switch Network": "Réseau de commutation",
    "Password Unlock": "Déverrouillage du mot de passe",
    "This page is password protected.": "Cette page est protégée par un mot de passe.",
    "Check Password": "Vérifier le mot de passe",
    "No Access": "Pas d’accès",
    "List of enabled Modules": "Liste des modules activés",
    "show": "montrer",
    "Events": "Evénements",
    "Unlockables": "Objets à débloquer",
    "Votings": "Votations",
    "Allowlist": "Liste d’admissibilité",
    "Partner Deals": "Offres des partenaires",
    "Token Search": "Recherche de Tokens",
    "My Tokens": "Mes Tokens",
    "go to": "aller à",
    "Minting": "Minting",
    "Raffles": "Les tombolas",
    "You are registered for this event": "Vous êtes inscrit à cet événement",
    "Event Type": "Type d’événement",
    "Start Date": "Date de début",
    "End Date": "Date de fin",
    "Sign up for this event": "S’inscrire à cet événement",
    "This event has ended.": "Cet événement est terminé.",
    "Unlocking Content": "Débloquer le contenu",
    "Successfully Unlocked": "Débloqué avec succès",
    "You have signed up for this event": "Vous vous êtes inscrit à cet événement",
    "View event": "Voir l’événement",
    "There was a problem while signing up for this event.": "Il y a eu un problème lors de l’inscription à cet événement.",
    "There was a problem showing this event. Please contact support on discord": "Il y a eu un problème pour afficher cet événement. Veuillez contacter le support sur Discord",
    "All": "Tous",
    "Filter by Tribe": "Filtrer par Tribu",
    "Upcoming": "À venir",
    "Completed": "Terminé",
    "For Me": "Pour moi",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "Il y a eu un problème pour lister tous les contenus déverrouillables. Veuillez contacter le support sur Discord",
    "Page not found": "Page non trouvée",
    "You are not authorized to see this page!": "Vous n’êtes pas autorisé à voir cette page !",
    "Please connect your wallet first": "Veuillez d’abord connecter votre Wallet",
    "Please sign in with Wallet first": "Veuillez d’abord vous connecter avec Wallet",
    "You need to own one of our NFTs to view this page.": "Vous devez posséder l’un de nos NFT pour consulter cette page.",
    "Connect your wallet to proceed.": "Connectez votre Wallet pour continuer.",
    "Please click Sign in with Ethereum to verify your NFT Ownership.": "Veuillez cliquer sur Se connecter avec Ethereum pour vérifier que vous êtes bien propriétaire d’un NFT.",
    "If you have already minted please wait a couple minutes in order for the transaction to be completed and ": "Si vous avez déjà minted, veuillez patienter quelques minutes afin que la transaction soit terminée et que vous puissiez vous inscrire. ",
    "click here ": "cliquez ici ",
    "to check your holder status.": "pour vérifier le statut de votre titulaire.",
    "Become a Holder": "Devenir titulaire",
    "Minting Section": "Section des Maintint",
    "Please connect your Wallet": "Veuillez connecter votre Wallet",
    "Sign in with your crypto wallet or Google account to mint NFTs.": "Connectez-vous avec votre Wallet cryptographique ou votre compte Google pour frapper des NFT.",
    "Terms and Conditions": "Conditions générales d’utilisation",
    "Successfully Minted": "Un succès Minted",
    "The mint was successful.": "L’opération a été couronnée de succès.",
    "You successfully minted the {tokenTitle} Token.": "Vous avez frappé avec succès le {tokenTitle} Token.",
    "You can see it in your Wallet": "Vous pouvez le voir dans votre Wallet",
    "Check your transaction on Polygonscan": "Vérifiez votre transaction sur Polygonscan",
    "Check your transaction on Etherscan": "Vérifiez votre transaction sur Etherscan",
    "Validating Voucher Code": "Valider le code du bon",
    "We are validating your voucher code.": "Nous sommes en train de valider votre code de réduction.",
    "Generating Airdrop": "Générer de l’Airdrop",
    "The token is going to be airdropped to your wallet.": "Le token va être déposé dans votre Wallet.",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "La transaction a été envoyée à la blockchain et nous attendons le résultat. Veuillez être patient, cela peut prendre un certain temps.",
    "Something went wrong minting your token": "Un problème s’est produit lors de la minting de votre token",
    "We could not mint your Token!": "Nous n’avons pas pu mine votre Token !",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Malheureusement, un problème s’est produit lors de l’envoi de votre transaction. Veuillez réessayer. Si l’erreur persiste, veuillez nous contacter à l’adresse support@bowline.app et inclure le message d’erreur complet.",
    "Something went wrong validating": "Un problème s’est produit lors de la validation",
    "We could not validate your code!": "Nous n’avons pas pu valider votre code !",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Malheureusement, nous n’avons pas pu valider votre code de bon d’achat. Veuillez réessayer. Si l’erreur persiste, veuillez nous contacter à l’adresse support@bowline.app et inclure le message d’erreur complet.",
    "There was a problem listing all the partner deals. Please contact support on discord": "Il y a eu un problème pour lister toutes les offres des partenaires. Veuillez contacter le support sur Discord",
    "You are registered for this raffle": "Vous êtes inscrit à cette tombola",
    "Raffle Type": "Type de tombola",
    "Sign up for this raffle": "S’inscrire à cette tombola",
    "This raffle has ended.": "Cette tombola est terminée.",
    "Raffle Winners": "Gagnants de la tombola",
    "You have signed up for this raffle": "Vous vous êtes inscrit à cette tombola",
    "View raffle": "Voir la tombola",
    "There was a problem while signing up for this raffle.": "Il y a eu un problème lors de l’inscription à cette tombola.",
    "There was a problem showing this raffle. Please contact support on discord": "Il y a eu un problème pour afficher cette tombola. Veuillez contacter le support sur Discord",
    "Static Page": "Page statique",
    "Loading...": "Chargement…",
    "Transaction Successful": "Transaction réussie",
    "Your tokens have been successfully minted": "Vos tokens ont été minted avec succès",
    "Missing name": "Nom manquant",
    "Check out this Token": "Vérifier ce Token",
    "Description": "Description",
    "Owner": "Propriétaire",
    "(You own this token)": "(Ce token vous appartient)",
    "Transfer to another Wallet": "Transfert vers un autre Wallet",
    "Traits": "Traits",
    "Blockchain": "Blockchain",
    "Token ID": "Token ID",
    "Token Standard": "Norme de Token",
    "Check on Marketplaces": "Vérifier sur les places de marché",
    "Rarible": "Rarible",
    "Related": "En rapport",
    "Unlockable Contents": "Contenu déverrouillable",
    "Token Transferred": "Token transféré",
    "Your token has been successfully transferred": "Votre token a été transféré avec succès",
    "Filter": "Filtre",
    "Filter Tokens": "Filtrer par Tokens",
    "Show {n} Tokens": "Afficher {n} Tokens",
    "The content has been unlocked successfully": "Le contenu a été déverrouillé avec succès",
    "View content": "Voir le contenu",
    "There was a problem unlocking this unlockable content.": "Il y a eu un problème pour débloquer ce contenu.",
    "Secret Description": "Description secrète",
    "Secret Files": "Dossiers secrets",
    "There was a problem showing this unlockable content. Please contact support on discord": "Il y a eu un problème pour afficher ce contenu déblocable. Veuillez contacter le support sur Discord",
    "Filter by Category": "Filtrer par Catégorie",
    "There are no unlockable contents yet": "Il n’y a pas encore de contenu déverrouillable",
    "You will receive a mail with a link to the password reset page.": "Vous recevrez un courriel contenant un lien vers la page de réinitialisation du mot de passe.",
    " ⚠ This app will be sunset on {dueFourWeeks} ⚠": " ⚠ This app will be sunset on {dueFourWeeks} ⚠",
    " ⚠ This app is currently deactivated ⚠ ": " ⚠ Cette application est actuellement désactivée ⚠ ",
    "immediate": "immédiat",
    "after vote": "après le vote",
    "after end": "après la fin",
    "Reveal Type": "Type de révélation",
    "Voting": "Vote",
    "Successfully Voted": "Voté avec succès",
    "There was a problem while sending your vote. Please try again": "Il y a eu un problème lors de l’envoi de votre vote. Veuillez réessayer",
    "There was a problem showing this voting. Please contact support on discord": "Il y a eu un problème pour afficher ce vote. Veuillez contacter le support sur Discord",
    "There are no votings yet": "Il n’y a pas encore de vote",
    "Open": "Ouvrir"
  },
  "it": {
    "Powered by": "",
    "Connect": "",
    "Wallet": "Wallet",
    "New alerts": "Nuovi avvisi",
    "Not signed in with Wallet": "Non connesso con Wallet",
    "Account": "Account",
    "Balance": "Saldo",
    "Signing Request": "Richiesta di firma",
    "Cancel": "Annulla",
    "Sign": "Firma",
    "You are not signed in with your Wallet yet": "Non hai ancora effettuato l'accesso con Wallet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "",
    "You are signed in with your Wallet": "Hai effettuato l'accesso con Wallet",
    "Copy address": "Copia indirizzo",
    "Tokens": "Token",
    "Settings": "Impostazioni",
    "Activity": "Attività",
    "Please \"Sign in with Wallet\" first.": "Effettua prima l'accesso con Wallet.",
    "See above": "Vedi sopra",
    "Change E-mail address": "",
    "Change": "",
    "Delete Account": "Elimina account",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Per eliminare il tuo account, contattaci: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "No Activity yet in this session": "",
    "Recent Signatures": "",
    "Transactions": "",
    "Disconnect": "",
    "Your Tokens related to this project": "",
    "Reload Tokens": "",
    "You do not have any Token in Wallet for this project": "",
    "Reload": "",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "",
    "or": "",
    "Connect to Wallet": "",
    "close": "",
    "Select a Wallet": "",
    "Metamask": "",
    "Ledger": "",
    "Coinbase Wallet": "",
    "Wallet Connect": "",
    "Social Connect": "",
    "Google": "",
    "Facebook": "",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "How to connect?": "",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "",
    "I do have a wallet.": "",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "",
    "No wallet yet?": "",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "",
    "Connect with Coinbase Wallet": "",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Want to learn more about this wallet?": "",
    "Learn how to install and setup this wallet on": "",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "",
    "Connect with Ledger": "",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "",
    "Connect with MetaMask": "",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Please use your existing MetaMask wallet.": "",
    "Connect with your preferred wallet": "",
    "With WalletConnect you can connect to almost any existing wallet.": "",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "",
    "Connect with your Google Account": "",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "",
    "Connect with Google": "",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "",
    "Connect to your Wallet via Social Connect.": "",
    "Connect with your Meta Account": "",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "",
    "Connect with Facebook": "",
    "Connect with Metamask": "",
    "Connect with Wallet Connect": "",
    "Verify your e-mail address": "",
    "The code was sent to your e-mail": "",
    "Please enter the code": "",
    "Verify": "",
    "Resend": "",
    "Discard": "",
    "Attending": "",
    "Type": "",
    "Start": "",
    "End": "",
    "Details": "",
    "Signed up": "",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "",
    "Home": "",
    "Already minted in total": "",
    "Already minted / wallet": "",
    "Mint for {mintPrice} ETH / each": "",
    "Paid Transaction": "",
    "Mint": "",
    "Buy Mint": "",
    "Select an option to pay for this mint": "",
    "Pay with Crypto": "",
    "We realized that your balance is too low. You need to have at least ": "",
    "Pay with Credit Card": "",
    "Please wait for the payment to be completed": "",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "",
    "No tokens available for minting": "",
    "Max tokens minted": "",
    "Mint will start soon. Stay tuned!": "",
    "Price/NFT": "",
    "Amount you already minted": "",
    "Current Price": "",
    "e.g. Voucher Code": "",
    "Sold out!": "",
    "Please visit the": "",
    "secondary market.": "",
    "OpenSea": "",
    "LooksRare": "",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "",
    "Switch to Ethereum network": "",
    "Switch to Rinkeby network": "",
    "Switch to Görli network": "",
    "Switch to Polygon network": "",
    "Switch to Mumbai network": "",
    "Preview": "",
    "Edit": "",
    "Update": "",
    "Create": "",
    "Update Page": "",
    "Create Page": "",
    "Do you want to update this page?": "",
    "Do you want to create this page?": "",
    "Set Title": "",
    "Set URL": "",
    "Locale": "",
    "Page Deleted": "",
    "Page was deleted successfully!": "",
    "Continue": "",
    "Back": "",
    "Missing title": "",
    "Expired": "",
    "Unlock": "",
    "Please connect your wallet to be able to unlock": "",
    "Welcome to {project}": "",
    "{project} asks for your email address": "",
    "{project} requires your email address to continue": "",
    "share email anonymised": "",
    "(recommended)": "",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "",
    "share email": "",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "",
    "don't share email": "",
    "Email address": "",
    "Your stored email": "",
    "Submit": "Invia",
    "By submitting you accept the": "",
    "terms of service": "",
    "terms of service for {projectTitle}": "",
    "Close": "",
    "data protection policy": "",
    "data protection policy for {projectTitle}": "",
    "of {projectTitle}.": "",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Share": "",
    "Bid for this Product": "",
    "you must bid at least": "",
    "Enter bid": "",
    "Bid can't be higher than current balance": "",
    "Warning: Your bid is lower than the current highest bid": "",
    "Your balance": "",
    "Place your Bid": "",
    "You cannot place a bid as you have insufficient funds.": "",
    "Please follow the confirmation steps in your wallet.": "",
    "Your bid is stored in our database.": "",
    "You have successfully placed your bid!": "",
    "We will notify you if you are outbid, or if you are the bid winner!": "",
    " view more": "",
    "This is a cached value, the price should soon be loaded from blockchain.": "",
    "Purchase": "",
    "Your item purchase was successful": "",
    "Okay": "",
    "Order Summary": "",
    "Amount: ": "",
    "Total": "",
    "Close Modal": "",
    "Transaction in Progress": "",
    "Please sign allowance": "",
    "please approve the interaction": "",
    "Checking your balance": "",
    "checking if your wallet has enough funds to buy this product": "",
    "Please sign transaction": "",
    "Metamask should open and ask you to sign the transaction": "",
    "Wait for transaction to be mined": "",
    "Depending on network traffic this could take a few minutes": "",
    "Something went wrong": "",
    "We could not purchase the product!": "",
    "Biling details": "",
    "First name": "",
    "This field is required": "",
    "Maximum length is 150": "",
    "Last name": "",
    "Company name": "",
    "Address": "",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "",
    "City": "",
    "Country": "",
    "Summary": "",
    "Products": "",
    "Price": "",
    "Continue Purchase": "",
    "Please Sign in with Wallet first before purchasing a product!": "",
    "Please connect your wallet first before being able to purchase products": "",
    "Click Purchase to complete your checkout.": "",
    "Sign in with Wallet": "Connetti con Wallet",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "",
    "Your retrieved earnings, that are ready to claim!": "",
    "pending rewards": "",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "",
    "Your lifetime earnings from staking.": "",
    "Claim": "",
    "Authorization required": "",
    "The provided voucher code is not valid": "",
    "The provided voucher code got already used. Each voucher code can be used only once.": "",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "",
    "Attention:": "",
    "We can't guarantee the exact order of the transactions.": "",
    "An Error occured!": "",
    "show full error message": "",
    "Processing": "",
    "Success!": "",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "",
    "a token": "",
    "created": "",
    "updated": "",
    "minted": "",
    "transfered": "",
    "You successfully {action} {resourceType}": "",
    "ok": "",
    "click to get back to overview screen": "",
    "Missing Name": "",
    "Select": "",
    "Selected": "",
    "Transfer Token": "",
    "Receiver": "",
    "Not a valid address!": "",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": "",
    "Transfer this NFT": "",
    "Purchase in Progress": "",
    "Signing with your wallet": "",
    "Please follow your wallet instructions to confirm the transaction.": "",
    "You confirmed the transaction with your wallet.": "",
    "Transaction is sent to the blockchain": "",
    "After signing the transaction it is send to the blockchain.": "",
    "Transaction is being processed": "",
    "Please check your wallets transaction history for your actual transaction status!": "",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "",
    "Select Tokens for Voting": "",
    "Select Token for Unlocking": "",
    "Select {n} token from these": "",
    "And": "",
    "Or": "",
    "Sorry you dont have any matching Token for unlocking": "",
    "Please fill out those inputs to unlock": "",
    "Entered value does not match email format": "",
    "Sign Up": "",
    "Vote": "",
    "Email": "Email",
    "Change password": "",
    "Insert your current password and the new password": "",
    "Current Password": "",
    "Password": "Password",
    "Save": "",
    "tokens": "",
    "community event": "",
    "partner event": "",
    "conference": "",
    "irl meetup": "",
    "irl party": "",
    "virtual meetup": "",
    "discord event": "",
    "ask me anything": "",
    "There are no votings options available": "",
    "vote": "",
    "Please connect your wallet to be able to vote": "",
    "Your Vote has been sent successfully": "",
    "Reveal": "",
    "get more Info": "",
    "This vote has ended. Thanks!": "",
    "Wallet Changed": "",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "",
    "(A QR code will be generated for {n} seconds)": "",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "",
    "Copy Private Key": "",
    "please complete your profile": "",
    "* means required": "",
    "Please connect to your wallet first.": "",
    "Contract Address": "",
    "Add my Wallet to this Allowlist": "",
    "Congratulations!": "",
    "You've been added to the Allowlist!": "",
    "Do you want to receive notifications on your email about this listing?": "",
    "Wallet Address": "",
    "You are already part of this allowlist!": "",
    "Name": "",
    "Description": "",
    "Items.header": "Ciao 1 Kind",
    "Items.header.offline": "Ciao offline 1 Kind",
    "YYYY-MM-DD": "DD.MM.YYYY",
    "LoadingContent.message": "",
    "ForgotPassword": "Dimenticato le credenziali? Clicca",
    "ForgotPassword.here": "qui",
    "NewPassword": "Nuova Password",
    "NewPasswordConfirmation": "Conferma Nuova Password",
    "PasswordConfirmation": "Conferma Password",
    "Number": "",
    "Text": "",
    "TextArea": "",
    "checkbox": "",
    "Switch": "",
    "Male": "",
    "Female": "",
    "RadioGroup": "",
    "select": "",
    "multi-select": "",
    "ForOFour.message": "",
    "ForOFour.button": "",
    "Home.header": "Ciao 1 Kind",
    "Home.header.offline": "Ciao offline 1 Kind"
  },
  "pt": {
    "Powered by": "Powered by",
    "Connect": "Desconectar",
    "Wallet": "Wallet",
    "New alerts": "Novos alertas",
    "Not signed in with Wallet": "Não conectado com Wallet",
    "Account": "Conta",
    "Balance": "Saldo",
    "Signing Request": "Pedido de Assinatura",
    "Cancel": "Cancelar",
    "Sign": "Assinar",
    "You are not signed in with your Wallet yet": "Ainda não está conectado com o ethereum",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "É-lhe pedido que assine uma mensagem para gerar uma ligação de início de sessão segura para a sua Wallet. Não teremos acesso aos seus fundos, NFTs ou realizaremos quaisquer acções com esta informação.",
    "You are signed in with your Wallet": "Está conectado com ethereum",
    "Copy address": "Copiar endereço",
    "Tokens": "Tokens",
    "Settings": "Configurações",
    "Activity": "Atividade",
    "Please \"Sign in with Wallet\" first.": "Por favor, use “Iniciar sessão com Wallet” primeiro.",
    "See above": "Veja acima",
    "Change E-mail address": "Endereço de e-mail",
    "Change": "Wallet Alterada",
    "Delete Account": "Excluir conta",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Para excluir sua conta, entre em contacto connosco: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para mais informação sobre o tratamento dos seus dados pessoais, consulte a nossa <a href='https://www.bowline.app/data-protection/' target='_blank'>Política de Privacidade</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Termos de serviço</a>.",
    "No Activity yet in this session": "Ainda não há atividade nesta sessão",
    "Recent Signatures": "Assinaturas recentes",
    "Transactions": "Transações",
    "Disconnect": "Desconectar",
    "Your Tokens related to this project": "Os seus Tokens relacionados com este projeto",
    "Reload Tokens": "Recarregar Tokens",
    "You do not have any Token in Wallet for this project": "Não possui nenhum Token na Wallet para este projeto",
    "Reload": "Recarregar",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "A sua Wallet pode conter Tokens de outros dApps. Por favor, visite os websites dos projetos para os ver ou verifique a sua Wallet, por exemplo",
    "or": "ou",
    "Connect to Wallet": "Conectar à Wallet",
    "close": "fechar",
    "Select a Wallet": "Selecionar a Wallet",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Ao conectar a Wallet aceita os nossos <a href='https://www.bowline.app/tos/' target='_blank'>Termos de Serviço</a>.",
    "How to connect?": "Como conectar?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Para se conectar e interagir de forma segura com este serviço, é necessário ter uma Wallet que suporte o protocolo Web3. Por favor, selecione uma das seguintes opções para se conectar à sua Wallet.",
    "I do have a wallet.": "Eu tenho uma Wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "Caso já tenha uma Wallet, selecione MetaMask, Ledger, Coinbase ou Wallet Connect para se conectar à sua Wallet.",
    "No wallet yet?": "Ainda não tem uma Wallet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "Não há problema, tem a conveniência de criar uma Wallet pessoal sem esforço, utilizando as suas contas de redes sociais. Criaremos uma Wallet sem custódia para si em segundo plano e pode exportar as suas chaves privadas, se necessário.",
    "Connect with Coinbase Wallet": "Conectar com Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "A Coinbase Wallet é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Want to learn more about this wallet?": "Quer saber mais sobre esta Wallet?",
    "Learn how to install and setup this wallet on": "Saiba como instalar e configurar esta Wallet em",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Adicionámos recentemente o suporte para esta Wallet. Se tiver algum problema, por favor informe-nos.",
    "Connect with Ledger": "Conectar com Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "O Ledger é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Depois de clicar no botão Conectar, pode utilizar o Ledger Live e o Ledger Connect a partir do seu computador ou telemóvel.",
    "Connect with MetaMask": "Conectar com MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "A MetaMask é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Please use your existing MetaMask wallet.": "Por favor utilize a sua MetaMask Wallet atual.",
    "Connect with your preferred wallet": "Conecte com a sua Wallet preferida",
    "With WalletConnect you can connect to almost any existing wallet.": "Com WalletConnect, pode conectar-se a quase todas as Wallets existentes.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Por favor clique em WalletConnect e digitalize o código QR com a Wallet da sua escolha ou seleccione a partir da lista de Wallets compatíveis.",
    "Connect with your Google Account": "Conectar com a sua conta Google",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Se ainda não tiver obtido uma crypto Wallet, tem a opção de utilizar as suas contas sociais actuais, como o Google ou o Facebook, para criar uma Wallet.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "Não temos acesso às suas chaves privadas, o que significa que não podemos gerir os seus fundos em seu nome. O utilizador, e apenas o utilizador, mantém o controlo total e a propriedade da sua Wallet e da respectiva chave privada associada.",
    "Connect with Google": "Conectar com Google",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Para estabelecer uma conexão com a sua conta Google, terá de conceder permissão a uma aplicação de terceiros confiável para criar uma Social Network Account que garante a gestão segura da sua Wallet sem custódia.",
    "Connect to your Wallet via Social Connect.": "Conecte-se à sua Wallet via Social Connect.",
    "Connect with your Meta Account": "Conecte-se com a sua Conta Meta",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "O Social Connect é uma forma nova, simples e rápida de criar uma Wallet. Portanto, a Bowline utiliza as suas contas sociais existentes, como o Google ou o Facebook, para criar uma Wallet que pode ser usada em qualquer aplicação descentralizada (dApp) alimentada pela Bowline.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "A Bowline não tem acesso às suas informações privadas e financeiras, apenas o utilizador possui controle total e propriedade sobre a sua Wallet.",
    "Connect with Facebook": "Conectar com Facebook",
    "Connect with Metamask": "Conectar com Metamask",
    "Connect with Wallet Connect": "Conectar com Wallet Connect",
    "Verify your e-mail address": "{project} solicita o seu endereço de e-mail",
    "The code was sent to your e-mail": "O código foi enviado para o seu e-mail",
    "Please enter the code": "Por favor, visite o",
    "Verify": "Verificar",
    "Resend": "Reenviar",
    "Discard": "Descartar",
    "Attending": "Participando",
    "Type": "Tipo",
    "Start": "Início",
    "End": "Fim",
    "Details": "Detalhes",
    "Signed up": "Registado",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Novo conteúdo está disponível. Por favor, clique aqui para atualizar a página e evitar inconsistências.",
    "Home": "Página inicial",
    "Already minted in total": "Já mintadas no total",
    "Already minted / wallet": "Já mintado / wallet",
    "Mint for {mintPrice} ETH / each": "Mint por {mintPrice} ETH / cada",
    "Paid Transaction": "Transação paga",
    "Mint": "Mint",
    "Buy Mint": "Comprar Mint",
    "Select an option to pay for this mint": "Selecione uma opção para pagar por este mint",
    "Pay with Crypto": "Pagar com Crypto",
    "We realized that your balance is too low. You need to have at least ": "Verificámos que o seu saldo é demasiado baixo. Precisa de ter pelo menos ",
    "Pay with Credit Card": "Pagar com cartão de crédito",
    "Please wait for the payment to be completed": "Por favor, aguarde até que o pagamento seja concluído",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "A transação foi enviada e receberá uma notificação na sua carteira Bowline",
    "No tokens available for minting": "Não há tokens disponíveis para minting",
    "Max tokens minted": "Máximo de tokens mintados",
    "Mint will start soon. Stay tuned!": "O mint começará em breve. Fique atento!",
    "Price/NFT": "Preço/NFT",
    "Amount you already minted": "Quantidade que já mintou",
    "Current Price": "Preço atual",
    "e.g. Voucher Code": "por exemplo, código de voucher",
    "Sold out!": "Esgotado!",
    "Please visit the": "Por favor, visite o",
    "secondary market.": "mercado secundário.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Está atualmente conectado a uma rede diferente daquela em que este contrato foi implementado. Por favor, altere a sua rede para {networkName} na sua Wallet.",
    "Switch to Ethereum network": "Mudar para a rede Ethereum",
    "Switch to Rinkeby network": "Mudar para a rede Rinkeby",
    "Switch to Görli network": "Mudar para a rede Görli",
    "Switch to Polygon network": "Mudar para a rede Polygon",
    "Switch to Mumbai network": "Mudar para a rede Mumbai",
    "Preview": "Pré-visualização",
    "Edit": "Editar",
    "Update": "Atualizar",
    "Create": "Criar",
    "Update Page": "Atualizar Página",
    "Create Page": "Criar Página",
    "Do you want to update this page?": "Deseja atualizar esta página?",
    "Do you want to create this page?": "Deseja criar esta página?",
    "Set Title": "Definir Título",
    "Set URL": "Definir URL",
    "Locale": "Linguagem",
    "Page Deleted": "Página Removida",
    "Page was deleted successfully!": "Página removida com sucesso!",
    "Continue": "Continuar",
    "Back": "Voltar",
    "Missing title": "Título em falta",
    "Expired": "Expirado",
    "Unlock": "Desbloquear",
    "Please connect your wallet to be able to unlock": "Por favor conecte com Wallet para poder desbloquear",
    "Welcome to {project}": "Bem-vindo ao {project}",
    "{project} asks for your email address": "{project} solicita o seu endereço de e-mail",
    "{project} requires your email address to continue": "{project} requer o seu endereço de e-mail para continuar",
    "share email anonymised": "partilhar e-mail anónimo",
    "(recommended)": "(recomendado)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} receberá apenas um endereço de e-mail anónimo, mas a bowline manterá o registo do seu endereço de e-mail real.",
    "share email": "partilhar e-mail",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} receberá o seu endereço de e-mail e o utilizará de acordo com a sua política de proteção de dados, e a bowline também armazenará o seu endereço de e-mail para enviar notificações.",
    "don't share email": "não partilhar e-mail",
    "Email address": "Endereço de e-mail",
    "Your stored email": "O seu e-mail armazenado",
    "Submit": "Submeter",
    "By submitting you accept the": "Ao submeter o pedido, o utilizador aceita os",
    "terms of service": "termos de serviço",
    "terms of service for {projectTitle}": "termos de serviço para {projectTitle}",
    "Close": "Fechar",
    "data protection policy": "política de proteção de dados",
    "data protection policy for {projectTitle}": "política de proteção de dados para {projectTitle}",
    "of {projectTitle}.": "de {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para mais informação sobre o tratamento dos seus dados pessoais através da bowline, por favor consulte esta <a href='https://www.bowline.app/data-protection/' target='_blank'>Política de Privacidade</a>.",
    "Share": "Partilhar",
    "Bid for this Product": "Licitar para este Produto",
    "you must bid at least": "é necessário licitar pelo menos",
    "Enter bid": "Introduzir proposta",
    "Bid can't be higher than current balance": "A proposta não pode ser superior ao saldo atual",
    "Warning: Your bid is lower than the current highest bid": "Aviso: A sua proposta é inferior à licitação mais alta atual",
    "Your balance": "O seu saldo",
    "Place your Bid": "Faça a sua proposta",
    "You cannot place a bid as you have insufficient funds.": "Não pode efetuar uma licitação porque não tem fundos suficientes.",
    "Please follow the confirmation steps in your wallet.": "Siga os passos de confirmação na sua Wallet.",
    "Your bid is stored in our database.": "A sua proposta foi armazenada na nossa base de dados.",
    "You have successfully placed your bid!": "A sua licitação foi efectuada com sucesso!",
    "We will notify you if you are outbid, or if you are the bid winner!": "Se a sua licitação for ultrapassada ou se for o vencedor da licitação, será notificado!",
    " view more": " ver mais",
    "This is a cached value, the price should soon be loaded from blockchain.": "Este é um valor em cache, o preço deve ser carregado em breve a partir de blockchain.",
    "Purchase": "Comprar",
    "Your item purchase was successful": "A sua compra do item foi bem-sucedida",
    "Okay": "Okay",
    "Order Summary": "Resumo do Pedido",
    "Amount: ": "Quantidade: ",
    "Total": "Total",
    "Close Modal": "Fechar Modal",
    "Transaction in Progress": "Transação em progresso",
    "Please sign allowance": "Por favor, assine a autorização",
    "please approve the interaction": "por favor, aprove a interação",
    "Checking your balance": "Verificando o seu saldo",
    "checking if your wallet has enough funds to buy this product": "verificando se a sua Wallet possui fundos suficientes para comprar este produto",
    "Please sign transaction": "Por favor, assine a transação",
    "Metamask should open and ask you to sign the transaction": "Metamask deveria abrir e pedir para o utilizador assinar a transação",
    "Wait for transaction to be mined": "Aguarde que a transação seja extraída",
    "Depending on network traffic this could take a few minutes": "Dependendo do tráfego da rede, isso pode demorar alguns minutos",
    "Something went wrong": "Algo correu mal",
    "We could not purchase the product!": "Não foi possível comprar o produto!",
    "Biling details": "Dados de faturação",
    "First name": "Primeiro nome",
    "This field is required": "Este campo é obrigatório",
    "Maximum length is 150": "",
    "Last name": "Apelido",
    "Company name": "Nome da empresa",
    "Address": "Morada",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "Código Postal",
    "City": "Cidade",
    "Country": "País",
    "Summary": "Resumo",
    "Products": "Produtos",
    "Price": "Preço",
    "Continue Purchase": "Continuar Compra",
    "Please Sign in with Wallet first before purchasing a product!": "Por favor, inicie sessão com Wallet antes de comprar um produto!",
    "Please connect your wallet first before being able to purchase products": "Por favor, conecte com Wallet primeiro antes de poder comprar produtos",
    "Click Purchase to complete your checkout.": "Clique em Comprar para concluir a compra.",
    "Sign in with Wallet": "Iniciar sessão com Wallet",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "ganhos não reclamados",
    "Your retrieved earnings, that are ready to claim!": "Os seus ganhos recolhidos, que estão prontos a ser reclamados!",
    "pending rewards": "recompensas pendentes",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "Saldo Disponível",
    "Your lifetime earnings from staking.": "",
    "Claim": "Recolher",
    "Authorization required": "Autorização necessária",
    "The provided voucher code is not valid": "O código do voucher fornecido não é válido",
    "The provided voucher code got already used. Each voucher code can be used only once.": "O código do voucher fornecido já foi utilizado. Cada código do voucher só pode ser utilizado uma vez.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "A sua transação já foi enviada para a blockchain. Parece que tentou mintar o mesmo token novamente. Por favor, seja paciente.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Se tentou mintar mais do que um Token, poderá ter sido apanhado pela nossa proteção de bots. Iremos rever manualmente a sua transação e enviar-lhe-emos o token mais tarde.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "",
    "Attention:": "Participando",
    "We can't guarantee the exact order of the transactions.": "",
    "An Error occured!": "Ocorreu um erro!",
    "show full error message": "mostrar mensagem de erro completa",
    "Processing": "Processando",
    "Success!": "Sucesso!",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "um evento",
    "a token": "um token",
    "created": "criado",
    "updated": "atualizado",
    "minted": "mintado",
    "transfered": "transferido",
    "You successfully {action} {resourceType}": "Realizou com sucesso {action} {resourceType}",
    "ok": "ok",
    "click to get back to overview screen": "clique para voltar ao ecrã de visão geral",
    "Missing Name": "Nome em falta",
    "Select": "Selecione",
    "Selected": "Selecionado",
    "Transfer Token": "Transferir Token",
    "Receiver": "Destinatário",
    "Not a valid address!": "Não é uma morada válida!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Vai transferir um nft para {address}. Esta operação não pode ser anulada",
    "Transfer this NFT": "Transferir este NFT",
    "Purchase in Progress": "Compra em andamento",
    "Signing with your wallet": "Assinar com Wallet",
    "Please follow your wallet instructions to confirm the transaction.": "Siga as instruções da sua Wallet para confirmar a transação.",
    "You confirmed the transaction with your wallet.": "Confirmou a transação com a sua Wallet.",
    "Transaction is sent to the blockchain": "Transação enviada para a blockchain",
    "After signing the transaction it is send to the blockchain.": "Depois de assinar a transação, esta é enviada para a blockchain.",
    "Transaction is being processed": "A transação está a ser processada",
    "Please check your wallets transaction history for your actual transaction status!": "Por favor, verifique o histórico de transações da sua Wallet para obter o status atual da sua transação!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Pode fechar este modal em qualquer altura - isso não afeta a sua transação. Fecharemos o modal assim que verificarmos que a transação está mined.",
    "Select Tokens for Voting": "Selecionar Tokens para Votação",
    "Select Token for Unlocking": "Selecionar Tokens para Desbloquear",
    "Select {n} token from these": "Selecionar {n} token dentre estes",
    "And": "E",
    "Or": "Ou",
    "Sorry you dont have any matching Token for unlocking": "Lamentamos, mas não tem nenhum Token correspondente para desbloqueio",
    "Please fill out those inputs to unlock": "Por favor, preencha esses campos para desbloquear",
    "Entered value does not match email format": "O valor inserido não corresponde ao formato de e-mail",
    "Sign Up": "Registar-se",
    "Vote": "Votar",
    "Email": "E-mail",
    "Change password": "Mudar palavra-passe",
    "Insert your current password and the new password": "Introduza a sua palavra-passe atual e a nova palavra-passe",
    "Current Password": "Palavra-passe atual",
    "Password": "Palavra-passe",
    "Save": "Guardar",
    "tokens": "tokens",
    "community event": "evento comunitário",
    "partner event": "evento de parceiro",
    "conference": "conferência",
    "irl meetup": "encontro presencial",
    "irl party": "festa presencial",
    "virtual meetup": "encontro virtual",
    "discord event": "evento no Discord",
    "ask me anything": "pergunte-me qualquer coisa",
    "There are no votings options available": "Não existem opções de votação disponíveis",
    "vote": "votar",
    "Please connect your wallet to be able to vote": "Por favor, conecte a sua carteira para poder votar",
    "Your Vote has been sent successfully": "O seu voto foi enviado com sucesso",
    "Reveal": "Revelar",
    "get more Info": "obter mais informações",
    "This vote has ended. Thanks!": "Esta votação terminou. Obrigado!",
    "Wallet Changed": "Wallet Alterada",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "Exportar Wallet",
    "(A QR code will be generated for {n} seconds)": "(Será gerado um código QR durante {n} segundos)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Digitalize o código QR utilizando a câmara do seu telemóvel ou a aplicação Wallet (por exemplo, Metamask) para exportar a sua Wallet ou clique no botão abaixo para copiar a chave.",
    "Copy Private Key": "Copiar Chave Privada",
    "please complete your profile": "por favor, complete o seu perfil",
    "* means required": "* significa obrigatório",
    "Please connect to your wallet first.": "Por favor, conecte com Wallet primeiro.",
    "Contract Address": "Endereço do Contrato",
    "Add my Wallet to this Allowlist": "Adicione a minha Wallet a esta Lista de Permissões",
    "Congratulations!": "Parabéns!",
    "You've been added to the Allowlist!": "Foi adicionado à Lista de Permissões!",
    "Do you want to receive notifications on your email about this listing?": "Deseja receber notificações no seu e-mail sobre este listagem?",
    "Wallet Address": "Endereço da Wallet",
    "You are already part of this allowlist!": "",
    "To mint this NFT please connect your wallet": "Para mintar este NFT, conecte a sua carteira",
    "This page is password protected.": "Esta página está protegida por palavra-passe.",
    "Check Password": "Verificar a palavra-passe",
    "No Access": "Sem Acesso",
    "List of enabled Modules": "Lista de módulos ativados",
    "show": "mostrar",
    "Events": "Eventos",
    "Unlockables": "Desbloqueáveis",
    "Votings": "Votações",
    "Allowlist": "Lista de permissões",
    "Partner Deals": "Ofertas de Parceiros",
    "Token Search": "Pesquisa de Token",
    "My Tokens": "Meus Tokens",
    "go to": "ir para",
    "Minting": "Minting",
    "Event Type": "Tipo de Evento",
    "Start Date": "Data de início",
    "End Date": "Data Final",
    "Sign up for this event": "Inscrever-se para este evento",
    "This event has ended.": "Este evento terminou.",
    "There was a problem showing this event. Please contact support on discord": "Houve um problema ao mostrar este evento. Por favor, entre em contato com o suporte no Discord",
    "There was a problem signing up for this event. Please contact support on discord": "Houve um problema ao registar-se para este evento. Por favor, entre em contato com o suporte no Discord",
    "Successfully Unlocked!": "Desbloqueado com sucesso!",
    "The event has been unlocked.": "O evento foi desbloqueado.",
    "Upcoming": "Em breve",
    "Completed": "Completado",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "Houve um problema ao listar todos os conteúdos desbloqueáveis. Por favor, entre em contato com o suporte no Discord",
    "Page not found": "Página não encontrada",
    "You are not authorized to see this page!": "Não está autorizado a ver esta página!",
    "Please connect your wallet first": "Por favor, conecte a sua carteira primeiro",
    "Please sign in with ethereum first": "Por favor, inicie sessão com ethereum primeiro",
    "You need to own one of our NFTs to view this page.": "Para visualizar esta página, é necessário possuir um dos nossos NFTs.",
    "Connect your wallet to proceed.": "Conecte a sua carteira para prosseguir.",
    "You didn't purchase our NFT yet? Click here to proceed.": "Ainda não comprou o nosso NFT? Clique aqui para prosseguir.",
    "Please click Sign in with Ethereum to verify your NFT Ownership.": "Clique em Iniciar sessão com Ethereum para verificar a sua propriedade de NFT.",
    "If you have already minted please wait a couple minutes in order for the transaction to be completed and ": "Se já tiver mintado, aguarde alguns minutos para que a transação seja concluída e ",
    "click here ": "clique aqui ",
    "to check your holder status.": "para verificar o seu estatuto de Holder.",
    "Become a Holder": "Torne-se um Holder",
    "Back to Home": "Voltar à Página Inicial",
    "Minting Section": "Secção de Minting",
    "Please connect your Wallet": "Por favor, conecte a sua Carteira",
    "Sign in with your crypto wallet or Google account to mint NFTs.": "Inicie sessão com a sua carteira crypto ou conta Google para mint NFTs.",
    "Terms and Conditions": "Termos e Condições",
    "Successfully Minted": "Mintado com sucesso",
    "The mint was successful.": "O mint foi bem sucedido.",
    "You successfully minted the {tokenTitle} Token.": "O Token {tokenTitle} foi montado com sucesso.",
    "You can see it in your Wallet": "Pode ver na sua carteira",
    "Check your transaction on Polygonscan": "Verificar a sua transação no Polygonscan",
    "Check your transaction on Etherscan": "Verificar a sua transação no Etherscan",
    "Validating Voucher Code": "Validando Código de Voucher",
    "We are validating your voucher code.": "Estamos a validar o seu código de voucher.",
    "Generating Airdrop": "Gerando Airdrop",
    "The token is going to be airdropped to your wallet.": "O token vai ser airdropped para a sua carteira.",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "A transação foi enviada para o blockchain e estamos a aguardar o resultado. Por favor, seja paciente, isso pode levar algum tempo.",
    "Something went wrong minting your token": "Algo correu mal ao mint o seu token",
    "We could not mint your Token!": "Não foi possível mintar o seu Token!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Infelizmente, algo correu mal ao enviar a sua transação. Por favor, tente novamente. Se o erro persistir, contacte-nos através de support@bowline.app e inclua a mensagem de erro completa.",
    "Something went wrong validating": "Algo correu mal na validação",
    "We could not validate your code!": "Não foi possível validar o seu código!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@bowline.app and include the full error message.": "Infelizmente, não foi possível validar o seu código de voucher. Por favor, tente novamente. Se o erro persistir, contacte-nos através de support@bowline.app e inclua a mensagem de erro completa.",
    "There was a problem listing all the partner deals. Please contact support on discord": "Houve um problema ao listar todas as ofertas de parceiros. Por favor, entre em contato com o suporte no Discord",
    "Your partner deal has been unlocked": "A sua oferta de parceiro foi desbloqueada",
    "There was a problem unlocking this Partner Deal. Please contact support on discord": "Houve um problema ao desbloquear esta Oferta de Parceiro. Por favor, entre em contato com o suporte no Discord",
    "Static Page": "Página Estática",
    "Loading...": "Carregando…",
    "Transaction Successful": "Transação bem-sucedida",
    "Your tokens have been successfully minted": "Os seus tokens foram montados com sucesso",
    "Missing name": "Nome em falta",
    "Check out this Token": "Verificar este Token",
    "Description": "Descrição",
    "Owner": "Proprietário",
    "(You own this token)": "(Este token é seu)",
    "Transfer to another Wallet": "Transferir para outra carteira",
    "Traits": "Características",
    "Blockchain": "Blockchain",
    "Token ID": "Token ID",
    "Token Standard": "Token Standard",
    "Check on Marketplaces": "Verificar nos Mercados",
    "Rarible": "Rarible",
    "Token Transferred": "Token transferido",
    "Your token has been successfully transferred": "O seu token foi transferido com sucesso",
    "Filter": "Filtro",
    "Filter Tokens": "Filtrar Tokens",
    "Show {n} Tokens": "Mostrar {n} Tokens",
    "Secret Description": "Descrição Secreta",
    "Secret Files": "Ficheiros Secretos",
    "There was a problem showing this unlockable content. Please contact support on discord": "Houve um problema ao mostrar este conteúdo desbloqueáveis. Por favor, entre em contato com o suporte no Discord",
    "There was a problem unlocking this unlockable content. Please contact support on discord": "Houve um problema ao desbloquear este conteúdo desbloqueável. Por favor, entre em contato com o suporte no Discord",
    "The content has been unlocked successfully": "O conteúdo foi desbloqueado com sucesso",
    "Filter by Category": "Filtrar por Categoria",
    "All": "Tudo",
    "Unlockable Contents": "Conteúdos Desbloqueáveis",
    "There are no unlockable contents yet": "Ainda não existem conteúdos desbloqueáveis",
    "You will receive a mail with a link to the password reset page.": "Você receberá um e-mail com um link para a página de redefinição da palavra-passe.",
    " ⚠ This app will be sunset on {dueFourWeeks} ⚠": " ⚠ Este aplicativo será descontinuado em {dueFourWeeks} ⚠",
    " ⚠ This app is currently deactivated ⚠ ": " ⚠ Esta aplicação está atualmente desactivada ⚠ ",
    "immediate": "imediato",
    "after vote": "após a votação",
    "after end": "após o fim",
    "Reveal Type": "Tipo de Revelação",
    "There was a problem showing this voting. Please contact support on discord": "Houve um problema ao mostrar esta votação. Por favor, entre em contato com o suporte no Discord",
    "There was a problem unlocking this voting. Please contact support on discord": "Houve um problema ao desbloquear esta votação. Por favor, entre em contato com o suporte no Discord",
    "Your Vote has been unlocked successfully": "O seu voto foi desbloqueado com sucesso",
    "There are no votings yet": "Ainda não existem votações",
    "Open": "Abrir"
  }
}